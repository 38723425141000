import axios from "axios";

export default async function getTickers({ queryKey }) {
  const [_, symbols, interval] = queryKey;

  const query = {
    query: `
    query{
      tickerWidget( symbols: "${symbols}", interval: "${interval}") {
        data 
      }
    }
              `,
  };

  var options = {
    method: "post",
    url:
      process.env.REACT_APP_NODE_ENV === "Development"
        ? process.env.REACT_APP_DEV_API_URL
        : process.env.REACT_APP_API_URL,
    data: query,
    headers: {
      Authorization: `API_KEY ${process.env.REACT_APP_API_KEY}`,
    },
  };

  const result = await axios.request(options);
  const symbolsArray = JSON.parse(result.data.data.tickerWidget.data);
  console.log(symbolsArray);

  return symbolsArray;
}
