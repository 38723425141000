import axios from "axios";

export default async function getSymbols({ queryKey }) {
  const [_, sector] = queryKey;

  const query = {
    query: `
  query Symbols {
    symbols(sector: "${sector}") {
    symbol
    logo_path
    }
  }
      `,
  };

  var options = {
    method: "post",
    url:
      process.env.REACT_APP_NODE_ENV === "Development"
        ? process.env.REACT_APP_DEV_API_URL
        : process.env.REACT_APP_API_URL,
    data: query,
    headers: {
      Authorization: `API_KEY ${process.env.REACT_APP_API_KEY}`,
    },
  };

  let response = await axios.request(options);

  const data = response.data.data.symbols;

  return data;
}
