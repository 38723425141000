import axios from "axios";
import { format, subHours } from "date-fns";

export default async function getNewsRating({ queryKey }) {
  let [_, threshold] = queryKey;

  const dateTimeCurrent = format(new Date(), "yyyy-MM-dd HH:mm:ss");
  const thresholdstring = threshold ? `(threshold: "${threshold})"` : "";
  const query = {
    query: `
      query Query {
        analystRatings${thresholdstring} {
          data_date
          price_change
          symbol
           analyst
           analyst_stars
           current_price
           price_target
           rating
           company_logo
           price_change
        } 
      }
        `,
  };

  var options = {
    method: "post",
    url:
      process.env.REACT_APP_NODE_ENV === "Development"
        ? process.env.REACT_APP_DEV_API_URL
        : process.env.REACT_APP_API_URL,
    data: query,
    headers: {
      Authorization: `API_KEY ${process.env.REACT_APP_API_KEY}`,
    },
  };

  let response = await axios.request(options);
  const data = response.data.data.analystRatings;
  // Shuffle array
  const shuffled = data.sort(() => 0.5 - Math.random());
  // Get sub-array of first n elements after shuffled
  let selected = shuffled.slice(0, 5);

  return selected;
}
