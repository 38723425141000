import React from "react";
import Lottie from "lottie-react-web";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import getNewsRating from "../../Queries/getNewsRating";
import loadingLottie from "../../Lottie/loadingLottie.json";
import lottieError from "../../Lottie/errorLottie.json";
import lottieData from "./newsRatingLottie";

function RQNewsArticleRating() {
  const { symbol } = useParams();
  const { exchange } = useParams();
  const { isLoading, data, isError } = useQuery(
    ["newsRating", symbol, exchange],
    getNewsRating
  );

  if (isLoading) {
    return (
      <Lottie
        width={100}
        height={100}
        options={{
          animationData: loadingLottie,
          loop: true,
        }}
      />
    );
  }
  if (isError) {
    return (
      <Lottie
        width={100}
        height={100}
        options={{
          animationData: lottieError,
          loop: true,
        }}
      />
    );
  }
  return lottieData(data);
}

export default RQNewsArticleRating;
