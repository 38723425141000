import axios from "axios";

export default async function getRandomTickers({ queryKey }) {
  const [_, symbolArray] = queryKey;

  const pickRandom = (arr, count) => {
    let _arr = [...arr];
    return [...Array(count)].map(
      () => _arr.splice(Math.floor(Math.random() * _arr.length), 1)[0]
    );
  };

  const filteredArray = pickRandom(symbolArray, 4);
  const symbolList = `${filteredArray[0].symbol},${filteredArray[1].symbol},${filteredArray[2].symbol},${filteredArray[3].symbol}`;

  const query1 = {
    query: `
    query{
      tickerWidget( symbols: "${symbolList}") {
        data 
      }
    }
              `,
  };

  var options1 = {
    method: "post",
    url:
      process.env.REACT_APP_NODE_ENV === "Development"
        ? process.env.REACT_APP_DEV_API_URL
        : process.env.REACT_APP_API_URL,
    data: query1,
    headers: {
      Authorization: `API_KEY ${process.env.REACT_APP_API_KEY}`,
    },
  };

  const result = await axios.request(options1);
  const symbolsArray = JSON.parse(result.data.data.tickerWidget.data);
  const data = symbolsArray;

  Object.values(data).forEach((item, index) => {
    item["image_source"] = filteredArray[index].logo_path;
  });

  return data;
}
