import React, { useState, useEffect } from "react";
import axios from "axios";

function CSVData() {
  const [msg, setMsg] = useState("");

  const query = {
    query: `
    query CsvData {
        csvData {
        msg  
        }
      }
          `,
  };

  var options = {
    method: "post",
    url: "http://localhost:4000/",
    data: query,
  };

  useEffect(() => {
    getCSV();
  }, []);

  const getCSV = async () => {
    let response = await axios.request(options);
    setMsg(response.data.data.csvData.msgh);
  };

  return <div>{msg}</div>;
}

export default CSVData;
