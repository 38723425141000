import React from "react";
import Lottie from "lottie-react-web";
import { useQuery } from "react-query";
import getDailyNewsArticles from "../../Queries/getDailyNewsArticles";
import loadingLottie from "../../Lottie/loadingLottie.json";
import lottieData from "./dailyNewsArticlesLottie";
import lottieError from "../../Lottie/errorLottie.json";

function RQEconomicCalendar() {
  const { isLoading, data, isError } = useQuery(
    "dailyNewsArticles",
    getDailyNewsArticles
  );

  if (isLoading) {
    return (
      <Lottie
        width={100}
        height={100}
        options={{
          animationData: loadingLottie,
          loop: true,
        }}
      />
    );
  }
  if (isError) {
    return (
      <Lottie
        width={100}
        height={100}
        options={{
          animationData: lottieError,
          loop: true,
        }}
      />
    );
  }
  return lottieData(data);
}

export default RQEconomicCalendar;
