import axios from "axios";
import { getMonth } from "date-fns";

export default async function getChart({ queryKey }) {
  const [_, symbol, timeFrame] = queryKey;

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const query = {
    query: `
    query{
      chartData(symbol: "${symbol}", timeFrame: "${timeFrame}"){
        data
      }
    }
        `,
  };

  var options = {
    method: "post",
    url:
      process.env.REACT_APP_NODE_ENV === "Development"
        ? process.env.REACT_APP_DEV_API_URL
        : process.env.REACT_APP_API_URL,
    data: query,
    headers: {
      Authorization: `API_KEY ${process.env.REACT_APP_API_KEY}`,
    },
  };

  const chartData = {
    name: "",
    symbol: "",
    chartjs: {
      labels: [],
      datasets: [
        {
          label: "",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: [],
        },
      ],
    },
  };

  const response = await axios.request(options);

  const dataArray = response.data.data.chartData.data;

  const parsedData = JSON.parse(dataArray);
  chartData.name = parsedData.symbol;
  chartData.symbol = parsedData.symbol;

  parsedData.values.map((item) => {
    const month = monthNames[getMonth(new Date(item.datetime))];
    chartData.chartjs.labels.push(month);
    chartData.chartjs.datasets[0].data.push(item.close);
  });

  console.log(chartData);

  return chartData;
}
