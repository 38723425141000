import React from "react";
import Lottie from "lottie-react-web";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import getNews from "../../Queries/getNews";
import getRandomSector from "../../Queries/getRandomSector";
import getSymbols from "../../Queries/getSymbols";
import loadingLottie from "../../Lottie/loadingLottie.json";
import lottieError from "../../Lottie/errorLottie.json";
import lottieData from "./newsLottie";

function RQNewsArticle() {
  const {
    isLoading: isSectorLoading,
    data: sector,
    isError: isSectorError,
  } = useQuery(["sectors"], getRandomSector);

  const {
    isLoading: isSymbolLoading,
    data: symbolArray,
    isError: isSymbolError,
  } = useQuery(["symbolsList", sector], getSymbols, {
    enabled: !!sector,
  });

  const { isLoading, data, isError } = useQuery(
    ["symbolNews", symbolArray],
    getNews,
    {
      enabled: !!symbolArray,
    }
  );

  if (isLoading || isSymbolLoading || isSectorLoading) {
    return (
      <Lottie
        width={100}
        height={100}
        options={{
          animationData: loadingLottie,
          loop: true,
        }}
      />
    );
  }
  if (isError || isSymbolError || isSectorError) {
    return (
      <Lottie
        width={100}
        height={100}
        options={{
          animationData: lottieError,
          loop: true,
        }}
      />
    );
  }
  return lottieData(data);
}

export default RQNewsArticle;
