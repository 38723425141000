export function getLocalTime(timezone) {
  let options = {
      timeZone: timezone,
      hour: "numeric",
      minute: "numeric",
    },
    formatter = new Intl.DateTimeFormat([], options);

  return formatter.format(new Date());
}

export function lottieUpdateText(lottieContainer, elementIndex, value) {
  const LottieContainer = lottieContainer.current;
  LottieContainer.anim.renderer.elements[elementIndex].updateDocumentData({
    t: value,
  });
}

Number.prototype.map = function (in_min, in_max, out_min, out_max) {
  return ((this - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
};

export function wordWrapToStringList(text, maxLength) {
  var result = [],
    line = [];
  var length = 0;
  text.split(" ").forEach(function (word) {
    if (length + word.length >= maxLength) {
      result.push(line.join(" "));
      line = [];
      length = 0;
    }
    length += word.length + 1;
    line.push(word);
  });
  if (line.length > 0) {
    result.push(line.join(" "));
  }
  return result;
}

export function isEmptyObject(o) {
  for (const key in o) {
    return o[key] === null || o[key] === undefined; // or just "return o[x];" for falsy values
  }
}
