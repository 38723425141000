import Lottie from "lottie-react-web";
import lottieError from "../../Lottie/errorLottie.json";

export default function dailyNewsLottie(data) {
  try {
    const lottieJSON = {
      v: "5.7.14",
      fr: 25,
      ip: 0,
      op: 2454,
      w: 1920,
      h: 1080,
      nm: "MexemNewsArticleMain",
      ddd: 0,
      assets: [
        {
          id: "ArticleImage4",
          w: 3000,
          h: 1999,
          u: "",
          p: data[3].image,
          e: 1,
        },
        {
          id: "ListImage4",
          w: 3000,
          h: 1999,
          u: "",
          p: data[3].image,
          e: 1,
        },
        {
          id: "ArticleImage3",
          w: 3000,
          h: 1999,
          u: "",
          p: data[2].image,
          e: 1,
        },
        {
          id: "ListImage3",
          w: 3000,
          h: 1999,
          u: "",
          p: data[2].image,
          e: 1,
        },
        {
          id: "ArticleImage2",
          w: 3000,
          h: 1999,
          u: "",
          p: data[1].image,
          e: 1,
        },
        {
          id: "ListImage2",
          w: 3000,
          h: 1999,
          u: "",
          p: data[1].image,
          e: 1,
        },
        {
          id: "ArticleImage1",
          w: 3000,
          h: 1999,
          u: "",
          p: data[0].image,
          e: 1,
        },
        {
          id: "ListImage1",
          w: 3000,
          h: 1999,
          u: "",
          p: data[0].image,
          e: 1,
        },
        {
          id: "comp_0",
          nm: "Intro & Transitions",
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 0,
              nm: "Transition Clean Round 10 4",
              refId: "comp_1",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              w: 1920,
              h: 1080,
              ip: 2130.8330078125,
              op: 2168.3330078125,
              st: 2130.8330078125,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 0,
              nm: "Transition Clean 29 3",
              refId: "comp_2",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              w: 1920,
              h: 1080,
              ip: 1884,
              op: 1921.5,
              st: 1884,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 0,
              nm: "Transition Clean Round 10 3",
              refId: "comp_1",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              w: 1920,
              h: 1080,
              ip: 1579.8330078125,
              op: 1617.3330078125,
              st: 1579.8330078125,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 4,
              ty: 0,
              nm: "Transition Clean 29 2",
              refId: "comp_2",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              w: 1920,
              h: 1080,
              ip: 1331,
              op: 1368.5,
              st: 1331,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 5,
              ty: 0,
              nm: "Transition Clean Round 10 2",
              refId: "comp_1",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              w: 1920,
              h: 1080,
              ip: 1027.8330078125,
              op: 1065.3330078125,
              st: 1027.8330078125,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 6,
              ty: 0,
              nm: "Transition Clean 29 1",
              refId: "comp_2",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              w: 1920,
              h: 1080,
              ip: 781,
              op: 818.5,
              st: 781,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 7,
              ty: 0,
              nm: "Transition Clean Round 10 1",
              refId: "comp_1",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              w: 1920,
              h: 1080,
              ip: 477.8330078125,
              op: 515.3330078125,
              st: 477.8330078125,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 8,
              ty: 0,
              nm: "Transition Clean 29 4",
              refId: "comp_2",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              w: 1920,
              h: 1080,
              ip: 232,
              op: 269.5,
              st: 232,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 9,
              ty: 0,
              nm: "NEXT Intro",
              refId: "comp_3",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [1920, 1080, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [50, 50, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              w: 3840,
              h: 2160,
              ip: 75,
              op: 250,
              st: 75,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 10,
              ty: 0,
              nm: "Bumper 02",
              refId: "comp_7",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [1920, 1080, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [50, 50, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              w: 3840,
              h: 2160,
              ip: 0,
              op: 125,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 11,
              ty: 1,
              nm: "White Solid 3",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              sw: 1920,
              sh: 1080,
              sc: "#ffffff",
              ip: 0,
              op: 250,
              st: 0,
              bm: 0,
            },
          ],
        },
        {
          id: "comp_1",
          nm: "Transition Clean Round 10 1",
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 4,
              nm: "Shape Layer 2",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-1544, 8],
                            [1584, 8],
                          ],
                          c: false,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "st",
                      c: {
                        a: 0,
                        k: [0.223529411765, 0.407843137255, 0.678431372549, 1],
                        ix: 3,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 4,
                      },
                      w: {
                        a: 0,
                        k: 1110,
                        ix: 5,
                      },
                      lc: 2,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Shape 1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "tm",
                  s: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.1],
                          y: [1],
                        },
                        o: {
                          x: [0.9],
                          y: [0],
                        },
                        t: 5.833,
                        s: [100],
                      },
                      {
                        t: 20.8333333333333,
                        s: [0],
                      },
                    ],
                    ix: 1,
                  },
                  e: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.1],
                          y: [1],
                        },
                        o: {
                          x: [0.9],
                          y: [0],
                        },
                        t: 16.667,
                        s: [100],
                      },
                      {
                        t: 32.5,
                        s: [0],
                      },
                    ],
                    ix: 2,
                  },
                  o: {
                    a: 0,
                    k: 0,
                    ix: 3,
                  },
                  m: 1,
                  ix: 2,
                  nm: "Trim Paths 1",
                  mn: "ADBE Vector Filter - Trim",
                  hd: false,
                },
              ],
              ip: 0,
              op: 37.5,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 4,
              nm: "Shape Layer 1",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-1544, 8],
                            [1584, 8],
                          ],
                          c: false,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "st",
                      c: {
                        a: 0,
                        k: [0, 0, 0, 1],
                        ix: 3,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 4,
                      },
                      w: {
                        a: 0,
                        k: 1110,
                        ix: 5,
                      },
                      lc: 2,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Shape 1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "tm",
                  s: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.1],
                          y: [1],
                        },
                        o: {
                          x: [0.9],
                          y: [0],
                        },
                        t: 0,
                        s: [100],
                      },
                      {
                        t: 20.8333333333333,
                        s: [0],
                      },
                    ],
                    ix: 1,
                  },
                  e: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.1],
                          y: [1],
                        },
                        o: {
                          x: [0.9],
                          y: [0],
                        },
                        t: 16.667,
                        s: [100],
                      },
                      {
                        t: 37.5,
                        s: [0],
                      },
                    ],
                    ix: 2,
                  },
                  o: {
                    a: 0,
                    k: 0,
                    ix: 3,
                  },
                  m: 1,
                  ix: 2,
                  nm: "Trim Paths 1",
                  mn: "ADBE Vector Filter - Trim",
                  hd: false,
                },
              ],
              ip: 0,
              op: 37.5,
              st: 0,
              bm: 0,
            },
          ],
        },
        {
          id: "comp_2",
          nm: "Transition Clean 29 1",
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 4,
              nm: "Shape Layer 1",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [952, 512, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [101.596, 97.49, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [752, -956],
                            [-1172, 160],
                          ],
                          c: false,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "st",
                      c: {
                        a: 0,
                        k: [0.223529411765, 0.407843137255, 0.678431372549, 1],
                        ix: 3,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 4,
                      },
                      w: {
                        a: 0,
                        k: 971,
                        ix: 5,
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Shape 1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "tm",
                  s: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.1],
                          y: [1],
                        },
                        o: {
                          x: [0.9],
                          y: [0],
                        },
                        t: 20,
                        s: [100],
                      },
                      {
                        t: 35,
                        s: [0],
                      },
                    ],
                    ix: 1,
                  },
                  e: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.1],
                          y: [1],
                        },
                        o: {
                          x: [0.9],
                          y: [0],
                        },
                        t: 0,
                        s: [100],
                      },
                      {
                        t: 20,
                        s: [0],
                      },
                    ],
                    ix: 2,
                  },
                  o: {
                    a: 0,
                    k: 0,
                    ix: 3,
                  },
                  m: 1,
                  ix: 2,
                  nm: "Trim Paths 1",
                  mn: "ADBE Vector Filter - Trim",
                  hd: false,
                },
              ],
              ip: 0,
              op: 38,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 4,
              nm: "Shape Layer 2",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1428, 1340, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [101.706, 97.5, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [752, -956],
                            [-1172, 160],
                          ],
                          c: false,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "st",
                      c: {
                        a: 0,
                        k: [0, 0, 0, 1],
                        ix: 3,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 4,
                      },
                      w: {
                        a: 0,
                        k: 971,
                        ix: 5,
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Shape 1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "tm",
                  s: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.1],
                          y: [1],
                        },
                        o: {
                          x: [0.9],
                          y: [0],
                        },
                        t: 23,
                        s: [0],
                      },
                      {
                        t: 38,
                        s: [100],
                      },
                    ],
                    ix: 1,
                  },
                  e: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.1],
                          y: [1],
                        },
                        o: {
                          x: [0.9],
                          y: [0],
                        },
                        t: 7,
                        s: [0],
                      },
                      {
                        t: 23,
                        s: [100],
                      },
                    ],
                    ix: 2,
                  },
                  o: {
                    a: 0,
                    k: 0,
                    ix: 3,
                  },
                  m: 1,
                  ix: 2,
                  nm: "Trim Paths 1",
                  mn: "ADBE Vector Filter - Trim",
                  hd: false,
                },
              ],
              ip: 0,
              op: 38,
              st: 0,
              bm: 0,
            },
          ],
        },
        {
          id: "comp_3",
          nm: "NEXT Intro",
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 0,
              nm: "TR [NEXT Intro]",
              td: 1,
              refId: "comp_4",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1920, 1080, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [1920, 1080, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              w: 3840,
              h: 2160,
              ip: 0,
              op: 175,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 0,
              nm: "NEXT Intro [Pre-comp]",
              tt: 3,
              refId: "comp_5",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1920, 1080, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [1920, 1080, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              w: 3840,
              h: 2160,
              ip: 0,
              op: 175,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 0,
              nm: "Text (NEXT Intro)",
              hd: true,
              refId: "comp_6",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1920, 1080, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [750, 500, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              w: 1500,
              h: 1000,
              ip: 0,
              op: 175,
              st: 0,
              bm: 0,
            },
          ],
        },
        {
          id: "comp_4",
          nm: "TR [NEXT Intro]",
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 4,
              nm: "Bottom To Top",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.667],
                        y: [1],
                      },
                      o: {
                        x: [0.333],
                        y: [0],
                      },
                      t: 0,
                      s: [20],
                    },
                    {
                      t: 25,
                      s: [0],
                    },
                  ],
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.057,
                        y: 1,
                      },
                      o: {
                        x: 0.253,
                        y: 0,
                      },
                      t: 0,
                      s: [1920, 5580, 0],
                      to: [0, -782, 0],
                      ti: [0, 782, 0],
                    },
                    {
                      t: 40,
                      s: [1920, 888, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              ef: [
                {
                  ty: 5,
                  nm: "Size",
                  np: 3,
                  mn: "ADBE Slider Control",
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 0,
                      nm: "Slider",
                      mn: "ADBE Slider Control-0001",
                      ix: 1,
                      v: {
                        a: 0,
                        k: 4500,
                        ix: 1,
                      },
                    },
                  ],
                },
                {
                  ty: 5,
                  nm: "Roundness",
                  np: 3,
                  mn: "ADBE Slider Control",
                  ix: 2,
                  en: 1,
                  ef: [
                    {
                      ty: 0,
                      nm: "Slider",
                      mn: "ADBE Slider Control-0001",
                      ix: 1,
                      v: {
                        a: 0,
                        k: 200,
                        ix: 1,
                      },
                    },
                  ],
                },
              ],
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ty: "rc",
                      d: 1,
                      s: {
                        a: 0,
                        k: [3840, 2160],
                        ix: 2,
                        x: "var $bm_rt;\nvar temp;\ntemp = effect('Size')('ADBE Slider Control-0001');\n$bm_rt = [\n    temp,\n    temp\n];",
                      },
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 4,
                        x: "var $bm_rt;\n$bm_rt = effect('Roundness')('ADBE Slider Control-0001');",
                      },
                      nm: "Rectangle Path 1",
                      mn: "ADBE Vector Shape - Rect",
                      hd: false,
                    },
                    {
                      ty: "st",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 3,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 4,
                      },
                      w: {
                        a: 0,
                        k: 2,
                        ix: 5,
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: true,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Rectangle 1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 750,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 4,
              nm: "Top To Bottom",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.667],
                        y: [1],
                      },
                      o: {
                        x: [0.333],
                        y: [0],
                      },
                      t: 0,
                      s: [-20],
                    },
                    {
                      t: 25,
                      s: [0],
                    },
                  ],
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.057,
                        y: 1,
                      },
                      o: {
                        x: 0.253,
                        y: 0,
                      },
                      t: 0,
                      s: [1920, -3420, 0],
                      to: [0, 718, 0],
                      ti: [0, -718, 0],
                    },
                    {
                      t: 40,
                      s: [1920, 888, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              ef: [
                {
                  ty: 5,
                  nm: "Size",
                  np: 3,
                  mn: "ADBE Slider Control",
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 0,
                      nm: "Slider",
                      mn: "ADBE Slider Control-0001",
                      ix: 1,
                      v: {
                        a: 0,
                        k: 4500,
                        ix: 1,
                      },
                    },
                  ],
                },
                {
                  ty: 5,
                  nm: "Roundness",
                  np: 3,
                  mn: "ADBE Slider Control",
                  ix: 2,
                  en: 1,
                  ef: [
                    {
                      ty: 0,
                      nm: "Slider",
                      mn: "ADBE Slider Control-0001",
                      ix: 1,
                      v: {
                        a: 0,
                        k: 200,
                        ix: 1,
                      },
                    },
                  ],
                },
              ],
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ty: "rc",
                      d: 1,
                      s: {
                        a: 0,
                        k: [3840, 2160],
                        ix: 2,
                        x: "var $bm_rt;\nvar temp;\ntemp = effect('Size')('ADBE Slider Control-0001');\n$bm_rt = [\n    temp,\n    temp\n];",
                      },
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 4,
                        x: "var $bm_rt;\n$bm_rt = effect('Roundness')('ADBE Slider Control-0001');",
                      },
                      nm: "Rectangle Path 1",
                      mn: "ADBE Vector Shape - Rect",
                      hd: false,
                    },
                    {
                      ty: "st",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 3,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 4,
                      },
                      w: {
                        a: 0,
                        k: 2,
                        ix: 5,
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: true,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Rectangle 1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 750,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 4,
              nm: "Left To Right",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.667],
                        y: [1],
                      },
                      o: {
                        x: [0.333],
                        y: [0],
                      },
                      t: 0,
                      s: [-20],
                    },
                    {
                      t: 25,
                      s: [0],
                    },
                  ],
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.005,
                        y: 1,
                      },
                      o: {
                        x: 0.341,
                        y: 0,
                      },
                      t: 0,
                      s: [-3080, 1080, 0],
                      to: [833.333, 0, 0],
                      ti: [-833.333, 0, 0],
                    },
                    {
                      t: 40,
                      s: [1920, 1080, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              ef: [
                {
                  ty: 5,
                  nm: "Size",
                  np: 3,
                  mn: "ADBE Slider Control",
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 0,
                      nm: "Slider",
                      mn: "ADBE Slider Control-0001",
                      ix: 1,
                      v: {
                        a: 0,
                        k: 4500,
                        ix: 1,
                      },
                    },
                  ],
                },
                {
                  ty: 5,
                  nm: "Roundness",
                  np: 3,
                  mn: "ADBE Slider Control",
                  ix: 2,
                  en: 1,
                  ef: [
                    {
                      ty: 0,
                      nm: "Slider",
                      mn: "ADBE Slider Control-0001",
                      ix: 1,
                      v: {
                        a: 0,
                        k: 250,
                        ix: 1,
                      },
                    },
                  ],
                },
              ],
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ty: "rc",
                      d: 1,
                      s: {
                        a: 0,
                        k: [3840, 2160],
                        ix: 2,
                        x: "var $bm_rt;\nvar temp;\ntemp = effect('Size')('ADBE Slider Control-0001');\n$bm_rt = [\n    temp,\n    temp\n];",
                      },
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 4,
                        x: "var $bm_rt;\n$bm_rt = effect('Roundness')('ADBE Slider Control-0001');",
                      },
                      nm: "Rectangle Path 1",
                      mn: "ADBE Vector Shape - Rect",
                      hd: false,
                    },
                    {
                      ty: "st",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 3,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 4,
                      },
                      w: {
                        a: 0,
                        k: 2,
                        ix: 5,
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: true,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Rectangle 1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 750,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 4,
              ty: 4,
              nm: "Right To Left",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.667],
                        y: [1],
                      },
                      o: {
                        x: [0.333],
                        y: [0],
                      },
                      t: 0,
                      s: [20],
                    },
                    {
                      t: 25,
                      s: [0],
                    },
                  ],
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.005,
                        y: 1,
                      },
                      o: {
                        x: 0.341,
                        y: 0,
                      },
                      t: 0,
                      s: [6920, 1080, 0],
                      to: [-833.333, 0, 0],
                      ti: [833.333, 0, 0],
                    },
                    {
                      t: 40,
                      s: [1920, 1080, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              ef: [
                {
                  ty: 5,
                  nm: "Size",
                  np: 3,
                  mn: "ADBE Slider Control",
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 0,
                      nm: "Slider",
                      mn: "ADBE Slider Control-0001",
                      ix: 1,
                      v: {
                        a: 0,
                        k: 4500,
                        ix: 1,
                      },
                    },
                  ],
                },
                {
                  ty: 5,
                  nm: "Roundness",
                  np: 3,
                  mn: "ADBE Slider Control",
                  ix: 2,
                  en: 1,
                  ef: [
                    {
                      ty: 0,
                      nm: "Slider",
                      mn: "ADBE Slider Control-0001",
                      ix: 1,
                      v: {
                        a: 0,
                        k: 250,
                        ix: 1,
                      },
                    },
                  ],
                },
              ],
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ty: "rc",
                      d: 1,
                      s: {
                        a: 0,
                        k: [3840, 2160],
                        ix: 2,
                        x: "var $bm_rt;\nvar temp;\ntemp = effect('Size')('ADBE Slider Control-0001');\n$bm_rt = [\n    temp,\n    temp\n];",
                      },
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 4,
                        x: "var $bm_rt;\n$bm_rt = effect('Roundness')('ADBE Slider Control-0001');",
                      },
                      nm: "Rectangle Path 1",
                      mn: "ADBE Vector Shape - Rect",
                      hd: false,
                    },
                    {
                      ty: "st",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 3,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 4,
                      },
                      w: {
                        a: 0,
                        k: 2,
                        ix: 5,
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: true,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Rectangle 1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 750,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 5,
              ty: 4,
              nm: "From The Centre",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.667],
                        y: [1],
                      },
                      o: {
                        x: [0.333],
                        y: [0],
                      },
                      t: 0,
                      s: [-45],
                    },
                    {
                      t: 25,
                      s: [0],
                    },
                  ],
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1920, 1080, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.297, 0.297, 0.667],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.33, 0.33, 0.33],
                        y: [0, 0, 0],
                      },
                      t: 0,
                      s: [0, 0, 100],
                    },
                    {
                      t: 40,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              ef: [
                {
                  ty: 5,
                  nm: "Size",
                  np: 3,
                  mn: "ADBE Slider Control",
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 0,
                      nm: "Slider",
                      mn: "ADBE Slider Control-0001",
                      ix: 1,
                      v: {
                        a: 0,
                        k: 4500,
                        ix: 1,
                      },
                    },
                  ],
                },
                {
                  ty: 5,
                  nm: "Roundness",
                  np: 3,
                  mn: "ADBE Slider Control",
                  ix: 2,
                  en: 1,
                  ef: [
                    {
                      ty: 0,
                      nm: "Slider",
                      mn: "ADBE Slider Control-0001",
                      ix: 1,
                      v: {
                        a: 0,
                        k: 200,
                        ix: 1,
                      },
                    },
                  ],
                },
              ],
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ty: "rc",
                      d: 1,
                      s: {
                        a: 0,
                        k: [3840, 2160],
                        ix: 2,
                        x: "var $bm_rt;\nvar temp;\ntemp = effect('Size')('ADBE Slider Control-0001');\n$bm_rt = [\n    temp,\n    temp\n];",
                      },
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 4,
                        x: "var $bm_rt;\n$bm_rt = effect('Roundness')('ADBE Slider Control-0001');",
                      },
                      nm: "Rectangle Path 1",
                      mn: "ADBE Vector Shape - Rect",
                      hd: false,
                    },
                    {
                      ty: "st",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 3,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 4,
                      },
                      w: {
                        a: 0,
                        k: 2,
                        ix: 5,
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: true,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Rectangle 1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 750,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 6,
              ty: 4,
              nm: "To The Center",
              td: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.667],
                        y: [1],
                      },
                      o: {
                        x: [0.333],
                        y: [0],
                      },
                      t: 0,
                      s: [-90],
                    },
                    {
                      t: 30,
                      s: [0],
                    },
                  ],
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1920, 1080, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.01, 0.01, 0.667],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.33, 0.33, 0.33],
                        y: [0, 0, 0],
                      },
                      t: 0,
                      s: [100, 100, 100],
                    },
                    {
                      t: 30,
                      s: [0, 0, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              ef: [
                {
                  ty: 5,
                  nm: "Size",
                  np: 3,
                  mn: "ADBE Slider Control",
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 0,
                      nm: "Slider",
                      mn: "ADBE Slider Control-0001",
                      ix: 1,
                      v: {
                        a: 0,
                        k: 4500,
                        ix: 1,
                      },
                    },
                  ],
                },
                {
                  ty: 5,
                  nm: "Roundness",
                  np: 3,
                  mn: "ADBE Slider Control",
                  ix: 2,
                  en: 1,
                  ef: [
                    {
                      ty: 0,
                      nm: "Slider",
                      mn: "ADBE Slider Control-0001",
                      ix: 1,
                      v: {
                        a: 0,
                        k: 200,
                        ix: 1,
                      },
                    },
                  ],
                },
              ],
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ty: "rc",
                      d: 1,
                      s: {
                        a: 0,
                        k: [3840, 2160],
                        ix: 2,
                        x: "var $bm_rt;\nvar temp;\ntemp = effect('Size')('ADBE Slider Control-0001');\n$bm_rt = [\n    temp,\n    temp\n];",
                      },
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 4,
                        x: "var $bm_rt;\n$bm_rt = effect('Roundness')('ADBE Slider Control-0001');",
                      },
                      nm: "Rectangle Path 1",
                      mn: "ADBE Vector Shape - Rect",
                      hd: false,
                    },
                    {
                      ty: "st",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 3,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 4,
                      },
                      w: {
                        a: 0,
                        k: 2,
                        ix: 5,
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: true,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Rectangle 1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 750,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 7,
              ty: 4,
              nm: "Window (In the Center)",
              tt: 4,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1920, 1080, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ty: "rc",
                      d: 1,
                      s: {
                        a: 0,
                        k: [3840, 2160],
                        ix: 2,
                      },
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      nm: "Rectangle Path 1",
                      mn: "ADBE Vector Shape - Rect",
                      hd: false,
                    },
                    {
                      ty: "st",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 3,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 4,
                      },
                      w: {
                        a: 0,
                        k: 2,
                        ix: 5,
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: true,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Rectangle 1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 750,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 8,
              ty: 4,
              nm: "Without Transition",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1920, 1080, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ty: "rc",
                      d: 1,
                      s: {
                        a: 0,
                        k: [3840, 2160],
                        ix: 2,
                      },
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      nm: "Rectangle Path 1",
                      mn: "ADBE Vector Shape - Rect",
                      hd: false,
                    },
                    {
                      ty: "st",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 3,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 4,
                      },
                      w: {
                        a: 0,
                        k: 2,
                        ix: 5,
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: true,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Rectangle 1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 750,
              st: 0,
              bm: 0,
            },
          ],
        },
        {
          id: "comp_5",
          nm: "NEXT Intro [Pre-comp]",
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 3,
              nm: "Move",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 0,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.833,
                        y: 0.833,
                      },
                      o: {
                        x: 0.167,
                        y: 0.167,
                      },
                      t: 0,
                      s: [1920, 1040, 0],
                      to: [0, 24, 0],
                      ti: [0, -24, 0],
                    },
                    {
                      t: 174,
                      s: [1920, 1184, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              ip: 0,
              op: 175,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 0,
              nm: "Text (NEXT Intro)",
              td: 1,
              refId: "comp_6",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1920, 880, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [750, 500, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              w: 1500,
              h: 1000,
              ip: 0,
              op: 750,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 4,
              nm: "Shape 03_Alfa",
              tt: 2,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0],
                        y: [1],
                      },
                      o: {
                        x: [0.333],
                        y: [0],
                      },
                      t: 15,
                      s: [5],
                    },
                    {
                      t: 60,
                      s: [-2],
                    },
                  ],
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0,
                        y: 1,
                      },
                      o: {
                        x: 0.333,
                        y: 0,
                      },
                      t: 15,
                      s: [1920, -2548.966, 0],
                      to: [0, 297.333, 0],
                      ti: [0, -297.333, 0],
                    },
                    {
                      t: 60,
                      s: [1920, -764.966, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              ef: [
                {
                  ty: 5,
                  nm: "Size",
                  np: 3,
                  mn: "ADBE Slider Control",
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 0,
                      nm: "Slider",
                      mn: "ADBE Slider Control-0001",
                      ix: 1,
                      v: {
                        a: 0,
                        k: 4500,
                        ix: 1,
                      },
                    },
                  ],
                },
                {
                  ty: 5,
                  nm: "Roundness",
                  np: 3,
                  mn: "ADBE Slider Control",
                  ix: 2,
                  en: 1,
                  ef: [
                    {
                      ty: 0,
                      nm: "Slider",
                      mn: "ADBE Slider Control-0001",
                      ix: 1,
                      v: {
                        a: 0,
                        k: 100,
                        ix: 1,
                      },
                    },
                  ],
                },
                {
                  ty: 5,
                  nm: "Transform",
                  np: 14,
                  mn: "ADBE Geometry2",
                  ix: 3,
                  en: 1,
                  ef: [
                    {
                      ty: 3,
                      nm: "Anchor Point",
                      mn: "ADBE Geometry2-0001",
                      ix: 1,
                      v: {
                        a: 0,
                        k: [1920, 1080],
                        ix: 1,
                      },
                    },
                    {
                      ty: 3,
                      nm: "Position",
                      mn: "ADBE Geometry2-0002",
                      ix: 2,
                      v: {
                        a: 0,
                        k: [1920, 1080],
                        ix: 2,
                      },
                    },
                    {
                      ty: 7,
                      nm: "Uniform Scale",
                      mn: "ADBE Geometry2-0011",
                      ix: 3,
                      v: {
                        a: 0,
                        k: 1,
                        ix: 3,
                      },
                    },
                    {
                      ty: 0,
                      nm: "Scale Height",
                      mn: "ADBE Geometry2-0003",
                      ix: 4,
                      v: {
                        a: 0,
                        k: 100,
                        ix: 4,
                      },
                    },
                    {
                      ty: 0,
                      nm: "Scale Width",
                      mn: "ADBE Geometry2-0004",
                      ix: 5,
                      v: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                    },
                    {
                      ty: 0,
                      nm: "Skew",
                      mn: "ADBE Geometry2-0005",
                      ix: 6,
                      v: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                    },
                    {
                      ty: 0,
                      nm: "Skew Axis",
                      mn: "ADBE Geometry2-0006",
                      ix: 7,
                      v: {
                        a: 0,
                        k: 0,
                        ix: 7,
                      },
                    },
                    {
                      ty: 0,
                      nm: "Rotation",
                      mn: "ADBE Geometry2-0007",
                      ix: 8,
                      v: {
                        a: 0,
                        k: 0,
                        ix: 8,
                      },
                    },
                    {
                      ty: 0,
                      nm: "Opacity",
                      mn: "ADBE Geometry2-0008",
                      ix: 9,
                      v: {
                        a: 0,
                        k: 100,
                        ix: 9,
                      },
                    },
                    {
                      ty: 7,
                      nm: "Use Composition’s Shutter Angle",
                      mn: "ADBE Geometry2-0009",
                      ix: 10,
                      v: {
                        a: 0,
                        k: 1,
                        ix: 10,
                      },
                    },
                    {
                      ty: 0,
                      nm: "Shutter Angle",
                      mn: "ADBE Geometry2-0010",
                      ix: 11,
                      v: {
                        a: 0,
                        k: 0,
                        ix: 11,
                      },
                    },
                    {
                      ty: 7,
                      nm: "Sampling",
                      mn: "ADBE Geometry2-0012",
                      ix: 12,
                      v: {
                        a: 0,
                        k: 1,
                        ix: 12,
                      },
                    },
                  ],
                },
              ],
              sy: [
                {
                  c: {
                    a: 0,
                    k: [0, 0.000373482704, 0.000398278236, 1],
                    ix: 2,
                  },
                  o: {
                    a: 0,
                    k: 50,
                    ix: 3,
                  },
                  a: {
                    a: 0,
                    k: 90,
                    ix: 5,
                  },
                  s: {
                    a: 0,
                    k: 50,
                    ix: 8,
                  },
                  d: {
                    a: 0,
                    k: 20,
                    ix: 6,
                  },
                  ch: {
                    a: 0,
                    k: 0,
                    ix: 7,
                  },
                  bm: {
                    a: 0,
                    k: 5,
                    ix: 1,
                  },
                  no: {
                    a: 0,
                    k: 0,
                    ix: 9,
                  },
                  lc: {
                    a: 0,
                    k: 1,
                    ix: 10,
                  },
                  ty: 1,
                  nm: "Drop Shadow",
                },
              ],
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ty: "rc",
                      d: 1,
                      s: {
                        a: 0,
                        k: [3840, 2160],
                        ix: 2,
                        x: "var $bm_rt;\nvar temp;\ntemp = effect('Size')('ADBE Slider Control-0001');\n$bm_rt = [\n    temp,\n    temp\n];",
                      },
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 4,
                        x: "var $bm_rt;\n$bm_rt = effect('Roundness')('ADBE Slider Control-0001');",
                      },
                      nm: "Rectangle Path 1",
                      mn: "ADBE Vector Shape - Rect",
                      hd: false,
                    },
                    {
                      ty: "st",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 3,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 4,
                      },
                      w: {
                        a: 0,
                        k: 2,
                        ix: 5,
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: true,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Rectangle 1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 15,
              op: 175,
              st: 15,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 4,
              ty: 4,
              nm: "Shape 03",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0],
                        y: [1],
                      },
                      o: {
                        x: [0.333],
                        y: [0],
                      },
                      t: 15,
                      s: [5],
                    },
                    {
                      t: 60,
                      s: [-2],
                    },
                  ],
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0,
                        y: 1,
                      },
                      o: {
                        x: 0.333,
                        y: 0,
                      },
                      t: 15,
                      s: [1920, -2548.966, 0],
                      to: [0, 297.333, 0],
                      ti: [0, -297.333, 0],
                    },
                    {
                      t: 60,
                      s: [1920, -764.966, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              ef: [
                {
                  ty: 5,
                  nm: "Size",
                  np: 3,
                  mn: "ADBE Slider Control",
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 0,
                      nm: "Slider",
                      mn: "ADBE Slider Control-0001",
                      ix: 1,
                      v: {
                        a: 0,
                        k: 4500,
                        ix: 1,
                      },
                    },
                  ],
                },
                {
                  ty: 5,
                  nm: "Roundness",
                  np: 3,
                  mn: "ADBE Slider Control",
                  ix: 2,
                  en: 1,
                  ef: [
                    {
                      ty: 0,
                      nm: "Slider",
                      mn: "ADBE Slider Control-0001",
                      ix: 1,
                      v: {
                        a: 0,
                        k: 100,
                        ix: 1,
                      },
                    },
                  ],
                },
                {
                  ty: 5,
                  nm: "Transform",
                  np: 14,
                  mn: "ADBE Geometry2",
                  ix: 3,
                  en: 1,
                  ef: [
                    {
                      ty: 3,
                      nm: "Anchor Point",
                      mn: "ADBE Geometry2-0001",
                      ix: 1,
                      v: {
                        a: 0,
                        k: [1920, 1080],
                        ix: 1,
                      },
                    },
                    {
                      ty: 3,
                      nm: "Position",
                      mn: "ADBE Geometry2-0002",
                      ix: 2,
                      v: {
                        a: 0,
                        k: [1920, 1080],
                        ix: 2,
                      },
                    },
                    {
                      ty: 7,
                      nm: "Uniform Scale",
                      mn: "ADBE Geometry2-0011",
                      ix: 3,
                      v: {
                        a: 0,
                        k: 1,
                        ix: 3,
                      },
                    },
                    {
                      ty: 0,
                      nm: "Scale Height",
                      mn: "ADBE Geometry2-0003",
                      ix: 4,
                      v: {
                        a: 0,
                        k: 100,
                        ix: 4,
                      },
                    },
                    {
                      ty: 0,
                      nm: "Scale Width",
                      mn: "ADBE Geometry2-0004",
                      ix: 5,
                      v: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                    },
                    {
                      ty: 0,
                      nm: "Skew",
                      mn: "ADBE Geometry2-0005",
                      ix: 6,
                      v: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                    },
                    {
                      ty: 0,
                      nm: "Skew Axis",
                      mn: "ADBE Geometry2-0006",
                      ix: 7,
                      v: {
                        a: 0,
                        k: 0,
                        ix: 7,
                      },
                    },
                    {
                      ty: 0,
                      nm: "Rotation",
                      mn: "ADBE Geometry2-0007",
                      ix: 8,
                      v: {
                        a: 0,
                        k: 0,
                        ix: 8,
                      },
                    },
                    {
                      ty: 0,
                      nm: "Opacity",
                      mn: "ADBE Geometry2-0008",
                      ix: 9,
                      v: {
                        a: 0,
                        k: 100,
                        ix: 9,
                      },
                    },
                    {
                      ty: 7,
                      nm: "Use Composition’s Shutter Angle",
                      mn: "ADBE Geometry2-0009",
                      ix: 10,
                      v: {
                        a: 0,
                        k: 1,
                        ix: 10,
                      },
                    },
                    {
                      ty: 0,
                      nm: "Shutter Angle",
                      mn: "ADBE Geometry2-0010",
                      ix: 11,
                      v: {
                        a: 0,
                        k: 0,
                        ix: 11,
                      },
                    },
                    {
                      ty: 7,
                      nm: "Sampling",
                      mn: "ADBE Geometry2-0012",
                      ix: 12,
                      v: {
                        a: 0,
                        k: 1,
                        ix: 12,
                      },
                    },
                  ],
                },
                {
                  ty: 21,
                  nm: "Fill",
                  np: 9,
                  mn: "ADBE Fill",
                  ix: 4,
                  en: 1,
                  ef: [
                    {
                      ty: 10,
                      nm: "Fill Mask",
                      mn: "ADBE Fill-0001",
                      ix: 1,
                      v: {
                        a: 0,
                        k: 0,
                        ix: 1,
                      },
                    },
                    {
                      ty: 7,
                      nm: "All Masks",
                      mn: "ADBE Fill-0007",
                      ix: 2,
                      v: {
                        a: 0,
                        k: 0,
                        ix: 2,
                      },
                    },
                    {
                      ty: 2,
                      nm: "Color",
                      mn: "ADBE Fill-0002",
                      ix: 3,
                      v: {
                        a: 0,
                        k: [0.254901975393, 0.349019616842, 0.588235318661, 1],
                        ix: 3,
                      },
                    },
                    {
                      ty: 7,
                      nm: "Invert",
                      mn: "ADBE Fill-0006",
                      ix: 4,
                      v: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                    },
                    {
                      ty: 0,
                      nm: "Horizontal Feather",
                      mn: "ADBE Fill-0003",
                      ix: 5,
                      v: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                    },
                    {
                      ty: 0,
                      nm: "Vertical Feather",
                      mn: "ADBE Fill-0004",
                      ix: 6,
                      v: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                    },
                    {
                      ty: 0,
                      nm: "Opacity",
                      mn: "ADBE Fill-0005",
                      ix: 7,
                      v: {
                        a: 0,
                        k: 1,
                        ix: 7,
                      },
                    },
                  ],
                },
              ],
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ty: "rc",
                      d: 1,
                      s: {
                        a: 0,
                        k: [3840, 2160],
                        ix: 2,
                        x: "var $bm_rt;\nvar temp;\ntemp = effect('Size')('ADBE Slider Control-0001');\n$bm_rt = [\n    temp,\n    temp\n];",
                      },
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 4,
                        x: "var $bm_rt;\n$bm_rt = effect('Roundness')('ADBE Slider Control-0001');",
                      },
                      nm: "Rectangle Path 1",
                      mn: "ADBE Vector Shape - Rect",
                      hd: false,
                    },
                    {
                      ty: "st",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 3,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 4,
                      },
                      w: {
                        a: 0,
                        k: 2,
                        ix: 5,
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: true,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.254901975393, 0.349019616842, 0.588235318661, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Rectangle 1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 15,
              op: 175,
              st: 15,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 5,
              ty: 4,
              nm: "MainShape 02",
              parent: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.713],
                        y: [1],
                      },
                      o: {
                        x: [0.333],
                        y: [0],
                      },
                      t: 20,
                      s: [-5],
                    },
                    {
                      t: 65,
                      s: [1.5],
                    },
                  ],
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0,
                        y: 1,
                      },
                      o: {
                        x: 0.333,
                        y: 0,
                      },
                      t: 20,
                      s: [0, -3676.966, 0],
                      to: [0, 309.333, 0],
                      ti: [0, -309.333, 0],
                    },
                    {
                      t: 60,
                      s: [0, -1820.966, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              ef: [
                {
                  ty: 5,
                  nm: "Size",
                  np: 3,
                  mn: "ADBE Slider Control",
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 0,
                      nm: "Slider",
                      mn: "ADBE Slider Control-0001",
                      ix: 1,
                      v: {
                        a: 0,
                        k: 4500,
                        ix: 1,
                      },
                    },
                  ],
                },
                {
                  ty: 5,
                  nm: "Roundness",
                  np: 3,
                  mn: "ADBE Slider Control",
                  ix: 2,
                  en: 1,
                  ef: [
                    {
                      ty: 0,
                      nm: "Slider",
                      mn: "ADBE Slider Control-0001",
                      ix: 1,
                      v: {
                        a: 0,
                        k: 0,
                        ix: 1,
                      },
                    },
                  ],
                },
                {
                  ty: 5,
                  nm: "Transform",
                  np: 14,
                  mn: "ADBE Geometry2",
                  ix: 3,
                  en: 1,
                  ef: [
                    {
                      ty: 3,
                      nm: "Anchor Point",
                      mn: "ADBE Geometry2-0001",
                      ix: 1,
                      v: {
                        a: 0,
                        k: [1920, 1080],
                        ix: 1,
                      },
                    },
                    {
                      ty: 3,
                      nm: "Position",
                      mn: "ADBE Geometry2-0002",
                      ix: 2,
                      v: {
                        a: 0,
                        k: [1920, 1080],
                        ix: 2,
                      },
                    },
                    {
                      ty: 7,
                      nm: "Uniform Scale",
                      mn: "ADBE Geometry2-0011",
                      ix: 3,
                      v: {
                        a: 0,
                        k: 1,
                        ix: 3,
                      },
                    },
                    {
                      ty: 0,
                      nm: "Scale Height",
                      mn: "ADBE Geometry2-0003",
                      ix: 4,
                      v: {
                        a: 0,
                        k: 100,
                        ix: 4,
                      },
                    },
                    {
                      ty: 0,
                      nm: "Scale Width",
                      mn: "ADBE Geometry2-0004",
                      ix: 5,
                      v: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                    },
                    {
                      ty: 0,
                      nm: "Skew",
                      mn: "ADBE Geometry2-0005",
                      ix: 6,
                      v: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                    },
                    {
                      ty: 0,
                      nm: "Skew Axis",
                      mn: "ADBE Geometry2-0006",
                      ix: 7,
                      v: {
                        a: 0,
                        k: 0,
                        ix: 7,
                      },
                    },
                    {
                      ty: 0,
                      nm: "Rotation",
                      mn: "ADBE Geometry2-0007",
                      ix: 8,
                      v: {
                        a: 0,
                        k: 0,
                        ix: 8,
                      },
                    },
                    {
                      ty: 0,
                      nm: "Opacity",
                      mn: "ADBE Geometry2-0008",
                      ix: 9,
                      v: {
                        a: 0,
                        k: 100,
                        ix: 9,
                      },
                    },
                    {
                      ty: 7,
                      nm: "Use Composition’s Shutter Angle",
                      mn: "ADBE Geometry2-0009",
                      ix: 10,
                      v: {
                        a: 0,
                        k: 1,
                        ix: 10,
                      },
                    },
                    {
                      ty: 0,
                      nm: "Shutter Angle",
                      mn: "ADBE Geometry2-0010",
                      ix: 11,
                      v: {
                        a: 0,
                        k: 0,
                        ix: 11,
                      },
                    },
                    {
                      ty: 7,
                      nm: "Sampling",
                      mn: "ADBE Geometry2-0012",
                      ix: 12,
                      v: {
                        a: 0,
                        k: 1,
                        ix: 12,
                      },
                    },
                  ],
                },
              ],
              sy: [
                {
                  c: {
                    a: 0,
                    k: [0, 0.000373482704, 0.000398278236, 1],
                    ix: 2,
                  },
                  o: {
                    a: 0,
                    k: 50,
                    ix: 3,
                  },
                  a: {
                    a: 0,
                    k: 90,
                    ix: 5,
                  },
                  s: {
                    a: 0,
                    k: 50,
                    ix: 8,
                  },
                  d: {
                    a: 0,
                    k: 20,
                    ix: 6,
                  },
                  ch: {
                    a: 0,
                    k: 0,
                    ix: 7,
                  },
                  bm: {
                    a: 0,
                    k: 5,
                    ix: 1,
                  },
                  no: {
                    a: 0,
                    k: 0,
                    ix: 9,
                  },
                  lc: {
                    a: 0,
                    k: 1,
                    ix: 10,
                  },
                  ty: 1,
                  nm: "Drop Shadow",
                },
              ],
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ty: "rc",
                      d: 1,
                      s: {
                        a: 0,
                        k: [3840, 2160],
                        ix: 2,
                        x: "var $bm_rt;\nvar temp;\ntemp = effect('Size')('ADBE Slider Control-0001');\n$bm_rt = [\n    temp,\n    temp\n];",
                      },
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 4,
                        x: "var $bm_rt;\n$bm_rt = effect('Roundness')('ADBE Slider Control-0001');",
                      },
                      nm: "Rectangle Path 1",
                      mn: "ADBE Vector Shape - Rect",
                      hd: false,
                    },
                    {
                      ty: "st",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 3,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 4,
                      },
                      w: {
                        a: 0,
                        k: 2,
                        ix: 5,
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: true,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.254901975393, 0.349019616842, 0.588235318661, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Rectangle 1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 15,
              op: 175,
              st: 15,
              bm: 0,
            },
          ],
        },
        {
          id: "comp_6",
          nm: "Text (NEXT Intro)",
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 5,
              nm: "NEXT",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [748.162, 495.038, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [15.049, -127.488, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 231,
                        f: "Poppins-Bold",
                        t: "HEADLINE",
                        ca: 1,
                        j: 2,
                        tr: 25,
                        lh: 41.6666488647461,
                        ls: 0,
                        fc: [0.914, 0.302, 0.255],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 0,
              op: 750,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 5,
              nm: "COMING UP",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [748.162, 639.038, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [15.049, -127.488, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 150,
                        f: "Poppins-Medium",
                        t: "NEWS",
                        ca: 1,
                        j: 2,
                        tr: 0,
                        lh: 0.00999999977648,
                        ls: 0,
                        fc: [0.914, 0.302, 0.255],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 0,
              op: 750,
              st: 0,
              bm: 0,
            },
          ],
        },
        {
          id: "comp_7",
          nm: "Bumper 02",
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 0,
              nm: "Bumper 02 [Pre-comp]",
              refId: "comp_8",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1920, 1080, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [1920, 1080, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              w: 3840,
              h: 2160,
              ip: 0,
              op: 125,
              st: 0,
              bm: 0,
            },
          ],
        },
        {
          id: "comp_8",
          nm: "Bumper 02 [Pre-comp]",
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 0,
              nm: "Logo (Bumper 02) [Pre-comp]",
              td: 1,
              refId: "comp_9",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0,
                        y: 1,
                      },
                      o: {
                        x: 0.333,
                        y: 0,
                      },
                      t: 5,
                      s: [1920, 3320, 0],
                      to: [0, -429.333, 0],
                      ti: [0, 429.333, 0],
                    },
                    {
                      t: 40,
                      s: [1920, 744, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [1920, 1080, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [70, 70, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              w: 3840,
              h: 2160,
              ip: 5,
              op: 130,
              st: 5,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 4,
              nm: "Shape 03_Alfa",
              tt: 2,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0],
                        y: [1],
                      },
                      o: {
                        x: [0.333],
                        y: [0],
                      },
                      t: 0,
                      s: [90],
                    },
                    {
                      t: 50,
                      s: [80],
                    },
                  ],
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0,
                        y: 1,
                      },
                      o: {
                        x: 0.333,
                        y: 0,
                      },
                      t: 0,
                      s: [2000, -2428.966, 0],
                      to: [-80, 293.333, 0],
                      ti: [80, -293.333, 0],
                    },
                    {
                      t: 50,
                      s: [1520, -668.966, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              ef: [
                {
                  ty: 5,
                  nm: "Size",
                  np: 3,
                  mn: "ADBE Slider Control",
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 0,
                      nm: "Slider",
                      mn: "ADBE Slider Control-0001",
                      ix: 1,
                      v: {
                        a: 0,
                        k: 4500,
                        ix: 1,
                      },
                    },
                  ],
                },
              ],
              sy: [
                {
                  c: {
                    a: 0,
                    k: [0, 0.000373482704, 0.000398278236, 1],
                    ix: 2,
                  },
                  o: {
                    a: 0,
                    k: 50,
                    ix: 3,
                  },
                  a: {
                    a: 0,
                    k: 90,
                    ix: 5,
                  },
                  s: {
                    a: 0,
                    k: 50,
                    ix: 8,
                  },
                  d: {
                    a: 0,
                    k: 20,
                    ix: 6,
                  },
                  ch: {
                    a: 0,
                    k: 0,
                    ix: 7,
                  },
                  bm: {
                    a: 0,
                    k: 5,
                    ix: 1,
                  },
                  no: {
                    a: 0,
                    k: 0,
                    ix: 9,
                  },
                  lc: {
                    a: 0,
                    k: 1,
                    ix: 10,
                  },
                  ty: 1,
                  nm: "Drop Shadow",
                },
              ],
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ty: "rc",
                      d: 1,
                      s: {
                        a: 0,
                        k: [3840, 2160],
                        ix: 2,
                        x: "var $bm_rt;\nvar temp;\ntemp = effect('Size')('ADBE Slider Control-0001');\n$bm_rt = [\n    temp,\n    temp\n];",
                      },
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      nm: "Rectangle Path 1",
                      mn: "ADBE Vector Shape - Rect",
                      hd: false,
                    },
                    {
                      ty: "st",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 3,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 4,
                      },
                      w: {
                        a: 0,
                        k: 2,
                        ix: 5,
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: true,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Rectangle 1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 125,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 4,
              nm: "MainShape 02",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0],
                        y: [1],
                      },
                      o: {
                        x: [0.333],
                        y: [0],
                      },
                      t: 10,
                      s: [92.5],
                    },
                    {
                      t: 50,
                      s: [1.5],
                    },
                  ],
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [3996.267, 1548.323, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [2250, 2250, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              ef: [
                {
                  ty: 5,
                  nm: "Size",
                  np: 3,
                  mn: "ADBE Slider Control",
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 0,
                      nm: "Slider",
                      mn: "ADBE Slider Control-0001",
                      ix: 1,
                      v: {
                        a: 0,
                        k: 4500,
                        ix: 1,
                      },
                    },
                  ],
                },
                {
                  ty: 5,
                  nm: "Roundness",
                  np: 3,
                  mn: "ADBE Slider Control",
                  ix: 2,
                  en: 1,
                  ef: [
                    {
                      ty: 0,
                      nm: "Slider",
                      mn: "ADBE Slider Control-0001",
                      ix: 1,
                      v: {
                        a: 0,
                        k: 0,
                        ix: 1,
                      },
                    },
                  ],
                },
                {
                  ty: 5,
                  nm: "Transform",
                  np: 14,
                  mn: "ADBE Geometry2",
                  ix: 3,
                  en: 1,
                  ef: [
                    {
                      ty: 3,
                      nm: "Anchor Point",
                      mn: "ADBE Geometry2-0001",
                      ix: 1,
                      v: {
                        a: 0,
                        k: [1920, 1080],
                        ix: 1,
                      },
                    },
                    {
                      ty: 3,
                      nm: "Position",
                      mn: "ADBE Geometry2-0002",
                      ix: 2,
                      v: {
                        a: 0,
                        k: [1920, 1080],
                        ix: 2,
                      },
                    },
                    {
                      ty: 7,
                      nm: "Uniform Scale",
                      mn: "ADBE Geometry2-0011",
                      ix: 3,
                      v: {
                        a: 0,
                        k: 1,
                        ix: 3,
                      },
                    },
                    {
                      ty: 0,
                      nm: "Scale Height",
                      mn: "ADBE Geometry2-0003",
                      ix: 4,
                      v: {
                        a: 0,
                        k: 100,
                        ix: 4,
                      },
                    },
                    {
                      ty: 0,
                      nm: "Scale Width",
                      mn: "ADBE Geometry2-0004",
                      ix: 5,
                      v: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                    },
                    {
                      ty: 0,
                      nm: "Skew",
                      mn: "ADBE Geometry2-0005",
                      ix: 6,
                      v: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                    },
                    {
                      ty: 0,
                      nm: "Skew Axis",
                      mn: "ADBE Geometry2-0006",
                      ix: 7,
                      v: {
                        a: 0,
                        k: 0,
                        ix: 7,
                      },
                    },
                    {
                      ty: 0,
                      nm: "Rotation",
                      mn: "ADBE Geometry2-0007",
                      ix: 8,
                      v: {
                        a: 0,
                        k: 0,
                        ix: 8,
                      },
                    },
                    {
                      ty: 0,
                      nm: "Opacity",
                      mn: "ADBE Geometry2-0008",
                      ix: 9,
                      v: {
                        a: 0,
                        k: 100,
                        ix: 9,
                      },
                    },
                    {
                      ty: 7,
                      nm: "Use Composition’s Shutter Angle",
                      mn: "ADBE Geometry2-0009",
                      ix: 10,
                      v: {
                        a: 0,
                        k: 1,
                        ix: 10,
                      },
                    },
                    {
                      ty: 0,
                      nm: "Shutter Angle",
                      mn: "ADBE Geometry2-0010",
                      ix: 11,
                      v: {
                        a: 0,
                        k: 0,
                        ix: 11,
                      },
                    },
                    {
                      ty: 7,
                      nm: "Sampling",
                      mn: "ADBE Geometry2-0012",
                      ix: 12,
                      v: {
                        a: 0,
                        k: 1,
                        ix: 12,
                      },
                    },
                  ],
                },
              ],
              sy: [
                {
                  c: {
                    a: 0,
                    k: [0, 0.000373482704, 0.000398278236, 1],
                    ix: 2,
                  },
                  o: {
                    a: 0,
                    k: 50,
                    ix: 3,
                  },
                  a: {
                    a: 0,
                    k: 90,
                    ix: 5,
                  },
                  s: {
                    a: 0,
                    k: 50,
                    ix: 8,
                  },
                  d: {
                    a: 0,
                    k: 20,
                    ix: 6,
                  },
                  ch: {
                    a: 0,
                    k: 0,
                    ix: 7,
                  },
                  bm: {
                    a: 0,
                    k: 5,
                    ix: 1,
                  },
                  no: {
                    a: 0,
                    k: 0,
                    ix: 9,
                  },
                  lc: {
                    a: 0,
                    k: 1,
                    ix: 10,
                  },
                  ty: 1,
                  nm: "Drop Shadow",
                },
              ],
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ty: "rc",
                      d: 1,
                      s: {
                        a: 0,
                        k: [3840, 2160],
                        ix: 2,
                        x: "var $bm_rt;\nvar temp;\ntemp = effect('Size')('ADBE Slider Control-0001');\n$bm_rt = [\n    temp,\n    temp\n];",
                      },
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 4,
                        x: "var $bm_rt;\n$bm_rt = effect('Roundness')('ADBE Slider Control-0001');",
                      },
                      nm: "Rectangle Path 1",
                      mn: "ADBE Vector Shape - Rect",
                      hd: false,
                    },
                    {
                      ty: "st",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 3,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 4,
                      },
                      w: {
                        a: 0,
                        k: 2,
                        ix: 5,
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: true,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.250980407, 0.341176480055, 0.57647061348, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Rectangle 1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 10,
              op: 125,
              st: 10,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 4,
              ty: 4,
              nm: "BG",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1920, 1080, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ty: "rc",
                      d: 1,
                      s: {
                        a: 0,
                        k: [3840, 2160],
                        ix: 2,
                      },
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      nm: "Rectangle Path 1",
                      mn: "ADBE Vector Shape - Rect",
                      hd: false,
                    },
                    {
                      ty: "st",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 3,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 4,
                      },
                      w: {
                        a: 0,
                        k: 2,
                        ix: 5,
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: true,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Rectangle 1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 125,
              st: 0,
              bm: 0,
            },
          ],
        },
        {
          id: "comp_9",
          nm: "Logo (Bumper 02) [Pre-comp]",
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 0,
              nm: "Logo (Bumper)",
              refId: "comp_10",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1920, 1080, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [1000, 1000, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              w: 2000,
              h: 2000,
              ip: 0,
              op: 125,
              st: 0,
              bm: 0,
            },
          ],
        },
        {
          id: "comp_10",
          nm: "Logo (Bumper)",
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 4,
              nm: "Layer 3",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1008.568, 1654.266, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [225, 225, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [14.126, 38.851],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-14.126, 38.851],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [0.575, -75.484],
                            [0.006, -75.786],
                            [-0.197, -75.669],
                            [-0.219, -75.657],
                            [-35.879, -56.521],
                            [-94.777, -24.872],
                            [-73.578, 44.012],
                            [-61.225, 17.5],
                            [-0.002, 75.786],
                            [24.838, 51.248],
                            [61.232, 16.916],
                            [73.582, 43.417],
                            [94.777, -25.465],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 4,
              nm: "Layer 2",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1011.885, 509.876, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [225, 225, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [1.375, 0.901],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [14.118, -28.255],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-14.023, -28.02],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [99.781, 82.862],
                            [99.804, 82.844],
                            [225.169, 15.742],
                            [223.389, -54.911],
                            [119.224, -141.422],
                            [119.201, -141.422],
                            [119.194, -141.43],
                            [-1.381, -79.174],
                            [-119.201, -143.202],
                            [-223.407, -56.669],
                            [-225.169, 13.974],
                            [-89.178, 82.325],
                            [-72.679, 143.202],
                            [84.689, 143.202],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 4,
              nm: "Layer 1",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1011.868, 1089.635, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [225, 225, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [21.707, 97.974],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-88.316, -21.181],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-52.995, 28.248],
                            [-7.107, 4.003],
                            [0, 0],
                            [51.214, 22.954],
                            [0, 0],
                            [-35.317, -12.364],
                            [-3.228, -1.39],
                            [-35.303, 12.365],
                            [0, 0],
                            [0, 0],
                            [50.09, -22.461],
                            [0, 0],
                            [-37.601, -20.831],
                            [-6.765, -3.599],
                            [-4.736, -1.842],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [-21.184, 90.062],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [9.625, -5.129],
                            [32.048, -18.075],
                            [0, 0],
                            [-51.215, -22.968],
                            [0, 0],
                            [34.34, 12.03],
                            [0, 0],
                            [35.31, -12.368],
                            [0, 0],
                            [-0.904, 0.386],
                            [-51.211, 22.953],
                            [0, 0],
                            [5.471, 3.044],
                            [5.213, 2.79],
                            [43.348, 17.03],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [88.291, -21.2],
                          ],
                          v: [
                            [308.179, -130.667],
                            [233.993, -194.251],
                            [196.917, -178.352],
                            [89.185, -132.432],
                            [-78.483, -135.91],
                            [-196.917, -180.103],
                            [-196.939, -180.129],
                            [-200.947, -181.842],
                            [-233.996, -196.015],
                            [-308.179, -132.432],
                            [-195.149, -17.645],
                            [-212.823, 98.919],
                            [-211.727, 99.15],
                            [-138.633, 114.824],
                            [-210.852, 122.854],
                            [-177.887, 169.959],
                            [-150.048, 161.164],
                            [-126.267, 153.664],
                            [-139.507, 165.579],
                            [-161.592, 185.448],
                            [-80.354, 180.161],
                            [-55.338, 166.473],
                            [-15.013, 141.307],
                            [-76.824, 65.37],
                            [-149.225, 33.578],
                            [-96.252, 24.739],
                            [-1.469, 63.638],
                            [96.252, 26.515],
                            [149.232, 35.336],
                            [149.195, 35.346],
                            [76.824, 67.131],
                            [15.01, 143.064],
                            [62.026, 171.962],
                            [80.358, 181.922],
                            [95.289, 188.845],
                            [161.586, 187.22],
                            [149.982, 176.757],
                            [126.275, 155.433],
                            [170.828, 164.565],
                            [185.527, 167.594],
                            [203.528, 135.1],
                            [209.874, 123.647],
                            [138.643, 116.578],
                            [212.816, 100.68],
                            [195.145, -15.868],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
          ],
        },
        {
          id: "comp_11",
          nm: "Mexem News Article 4",
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 0,
              nm: ".NewsDescription4",
              cl: "NewsDescription4",
              refId: "comp_12",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              w: 1920,
              h: 1080,
              ip: 118,
              op: 1425,
              st: 118,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 0,
              nm: ".NewsLower4",
              cl: "NewsLower4",
              refId: "comp_13",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              w: 1920,
              h: 1080,
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 4,
              nm: "BGMask",
              td: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 4,
              ty: 2,
              nm: ".ImageNews4",
              cl: "ImageNews4",
              tt: 2,
              refId: "ArticleImage4",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1452, 292, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [2218.651, 532.377, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.833, 0.833, 0.833],
                        y: [0.833, 0.833, 0.833],
                      },
                      o: {
                        x: [0.167, 0.167, 0.167],
                        y: [0.167, 0.167, 0.167],
                      },
                      t: 0,
                      s: [66.792, 66.792, 100],
                    },
                    {
                      t: 305,
                      s: [82.338, 82.338, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
          ],
        },
        {
          id: "comp_12",
          nm: "DescriptionBox News 4",
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 4,
              nm: "DescriptionBG 2",
              td: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 90,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 13,
                      s: [1436.647, 289.305, 0],
                      to: [-54.125, -18.792, 0],
                      ti: [54.125, 18.792, 0],
                    },
                    {
                      t: 25,
                      s: [1111.897, 176.555, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [-344.595, -146.509, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 0.1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0.9, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 13,
                      s: [6.702, 15.619, 100],
                    },
                    {
                      t: 25,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [344.595, 146.509],
                            [-344.595, 146.509],
                            [-344.595, -146.509],
                            [344.595, -146.509],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "gf",
                      o: {
                        a: 0,
                        k: 100,
                        ix: 10,
                      },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [
                            0, 0.086, 0.047, 0.243, 0.5, 0.1, 0.045, 0.259, 1,
                            0.114, 0.043, 0.275,
                          ],
                          ix: 9,
                        },
                      },
                      s: {
                        a: 0,
                        k: [-345.492, -0.672],
                        ix: 5,
                      },
                      e: {
                        a: 0,
                        k: [343.699, -0.672],
                        ix: 6,
                      },
                      t: 1,
                      nm: "Gradient Fill 1",
                      mn: "ADBE Vector Graphic - G-Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 5,
              nm: ".NewsDescription4",
              cl: "NewsDescription4",
              tt: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1451.583, 310.336, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [-5.493, -26.604, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        sz: [596.73046875, 223.542282104492],
                        ps: [-298.365234375, -111.771141052246],
                        s: 29.9096698760986,
                        f: "Poppins-SemiBold",
                        t: data[3].description,
                        ca: 1,
                        j: 0,
                        tr: 0,
                        lh: 35.8916091918945,
                        ls: 0,
                        fc: [0.949, 0.949, 0.949],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 4,
              nm: "DescriptionSquareHalf2",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 13,
                      s: [1459.354, 311.778, 0],
                      to: [53, 22.75, 0],
                      ti: [-53, -22.75, 0],
                    },
                    {
                      t: 25,
                      s: [1777.354, 448.278, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 0.1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0.9, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 0,
                      s: [0, 0, 100],
                    },
                    {
                      t: 6,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [23.408, -23.408],
                            [23.408, 23.408],
                            [-23.408, 23.408],
                          ],
                          c: false,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.956862747669, 0.956862747669, 0.956862747669, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 4,
              ty: 4,
              nm: "DescriptionSquareHalf1",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 13,
                      s: [1459.867, 312.086, 0],
                      to: [-54.094, -18.687, 0],
                      ti: [54.094, 18.687, 0],
                    },
                    {
                      t: 25,
                      s: [1135.304, 199.962, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 0.1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0.9, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 0,
                      s: [0, 0, 100],
                    },
                    {
                      t: 6,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-23.408, 23.408],
                            [-23.408, -23.408],
                            [23.408, -23.408],
                          ],
                          c: false,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.956862747669, 0.956862747669, 0.956862747669, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 5,
              ty: 4,
              nm: "DescriptionBG",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 90,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 13,
                      s: [1436.647, 289.305, 0],
                      to: [-54.125, -18.792, 0],
                      ti: [54.125, 18.792, 0],
                    },
                    {
                      t: 25,
                      s: [1111.897, 176.555, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [-344.595, -146.509, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 0.1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0.9, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 13,
                      s: [6.702, 15.619, 100],
                    },
                    {
                      t: 25,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [344.595, 146.509],
                            [-344.595, 146.509],
                            [-344.595, -146.509],
                            [344.595, -146.509],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "gf",
                      o: {
                        a: 0,
                        k: 100,
                        ix: 10,
                      },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [
                            0, 0.086, 0.047, 0.243, 0.5, 0.1, 0.045, 0.259, 1,
                            0.114, 0.043, 0.275,
                          ],
                          ix: 9,
                        },
                      },
                      s: {
                        a: 0,
                        k: [-345.492, -0.672],
                        ix: 5,
                      },
                      e: {
                        a: 0,
                        k: [343.699, -0.672],
                        ix: 6,
                      },
                      t: 1,
                      nm: "Gradient Fill 1",
                      mn: "ADBE Vector Graphic - G-Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
          ],
        },
        {
          id: "comp_13",
          nm: "Lower Third News 4",
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 4,
              nm: "HeadlineBox 2",
              td: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [83.282, 885.028, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [83.282, 880.267, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 45,
                      s: [0, 100, 100],
                    },
                    {
                      t: 56,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [165.902, 34.873],
                            [-165.902, 34.873],
                            [-165.902, -34.873],
                            [165.902, -34.873],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.227450981736, 0.407843142748, 0.68235296011, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [249.184, 880.267],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 5,
              nm: "HeadlineText",
              tt: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 63,
                      s: [-214.275, 886.46, 0],
                      to: [57.333, 0, 0],
                      ti: [-57.333, 0, 0],
                    },
                    {
                      t: 74,
                      s: [129.725, 886.46, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [3.233, -16.42, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 46.8478507995605,
                        f: "Poppins-SemiBold",
                        t: "HEADLINES",
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 56.2174301147461,
                        ls: 0,
                        fc: [0.949, 0.949, 0.949],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 4,
              nm: "HeadlineBox",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [83.282, 885.028, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [83.282, 880.267, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 52,
                      s: [0, 100, 100],
                    },
                    {
                      t: 63,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [165.902, 34.873],
                            [-165.902, 34.873],
                            [-165.902, -34.873],
                            [165.902, -34.873],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.227450981736, 0.407843142748, 0.68235296011, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [249.184, 880.267],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 4,
              ty: 5,
              nm: ".NewsHealineNumber4",
              cl: "NewsHealineNumber4",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 0,
                      s: [126.696, 1284.042, 0],
                      to: [0, -48, 0],
                      ti: [0, 48, 0],
                    },
                    {
                      i: {
                        x: 0.1,
                        y: 0.1,
                      },
                      o: {
                        x: 0.9,
                        y: 0.9,
                      },
                      t: 15,
                      s: [126.696, 996.042, 0],
                      to: [0, 0, 0],
                      ti: [0, 0, 0],
                    },
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 30,
                      s: [126.696, 996.042, 0],
                      to: [249, 0, 0],
                      ti: [-249, 0, 0],
                    },
                    {
                      t: 44,
                      s: [1620.696, 996.042, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 200.433181762695,
                        f: "Poppins-Bold",
                        t: "4",
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 240.519821166992,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 5,
              ty: 4,
              nm: "LowerThirdLine",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [950.524, 1033.348, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-843.519, 0],
                            [843.519, 0],
                          ],
                          c: false,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "st",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 3,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 4,
                      },
                      w: {
                        a: 0,
                        k: 19,
                        ix: 5,
                      },
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "tm",
                  s: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.1],
                          y: [1],
                        },
                        o: {
                          x: [0.9],
                          y: [0],
                        },
                        t: 30,
                        s: [100],
                      },
                      {
                        t: 44,
                        s: [0],
                      },
                    ],
                    ix: 1,
                  },
                  e: {
                    a: 0,
                    k: 100,
                    ix: 2,
                  },
                  o: {
                    a: 0,
                    k: 0,
                    ix: 3,
                  },
                  m: 1,
                  ix: 2,
                  nm: "Trim Paths 1",
                  mn: "ADBE Vector Filter - Trim",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 6,
              ty: 4,
              nm: "LowerThirdBox 3",
              td: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1794.043, 969.858, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [843.231, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 35,
                      s: [0, 100, 100],
                    },
                    {
                      t: 49,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [843.231, 63.49],
                            [-843.231, 63.49],
                            [-843.231, -63.49],
                            [843.231, -63.49],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "gf",
                      o: {
                        a: 0,
                        k: 100,
                        ix: 10,
                      },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [
                            0, 0.086, 0.047, 0.243, 0.5, 0.1, 0.045, 0.259, 1,
                            0.114, 0.043, 0.275,
                          ],
                          ix: 9,
                        },
                      },
                      s: {
                        a: 0,
                        k: [-91.813, 365.534],
                        ix: 5,
                      },
                      e: {
                        a: 0,
                        k: [62.363, -251.17],
                        ix: 6,
                      },
                      t: 1,
                      nm: "Gradient Fill 1",
                      mn: "ADBE Vector Graphic - G-Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 7,
              ty: 5,
              nm: ".NewsHeadline4",
              cl: "NewsHeadline4",
              tt: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.25,
                        y: 1,
                      },
                      o: {
                        x: 0.75,
                        y: 0,
                      },
                      t: 78,
                      s: [274.591, 1171.409, 0],
                      to: [0, -30.333, 0],
                      ti: [0, 30.333, 0],
                    },
                    {
                      t: 104,
                      s: [274.591, 989.409, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 35,
                        f: "Poppins-SemiBold",
                        t: data[3].headline,
                        ca: 1,
                        j: 0,
                        tr: 0,
                        lh: 68.6805572509766,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 8,
              ty: 4,
              nm: "LowerThirdBox 2",
              td: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1794.043, 969.858, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [843.231, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 35,
                      s: [0, 100, 100],
                    },
                    {
                      t: 49,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [843.231, 63.49],
                            [-843.231, 63.49],
                            [-843.231, -63.49],
                            [843.231, -63.49],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "gf",
                      o: {
                        a: 0,
                        k: 100,
                        ix: 10,
                      },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [
                            0, 0.086, 0.047, 0.243, 0.5, 0.1, 0.045, 0.259, 1,
                            0.114, 0.043, 0.275,
                          ],
                          ix: 9,
                        },
                      },
                      s: {
                        a: 0,
                        k: [-91.813, 365.534],
                        ix: 5,
                      },
                      e: {
                        a: 0,
                        k: [62.363, -251.17],
                        ix: 6,
                      },
                      t: 1,
                      nm: "Gradient Fill 1",
                      mn: "ADBE Vector Graphic - G-Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 9,
              ty: 4,
              nm: "Logo",
              tt: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.25,
                        y: 1,
                      },
                      o: {
                        x: 0.75,
                        y: 0,
                      },
                      t: 52,
                      s: [158.591, 1097.409, 0],
                      to: [0, -21, 0],
                      ti: [0, 21, 0],
                    },
                    {
                      t: 78,
                      s: [158.591, 971.409, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [158.591, 971.409, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [1.249, 3.434],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-1.249, 3.434],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [0.051, -6.672],
                            [0, -6.698],
                            [-0.017, -6.688],
                            [-0.019, -6.687],
                            [-3.171, -4.996],
                            [-8.377, -2.198],
                            [-6.503, 3.89],
                            [-5.411, 1.547],
                            [0, 6.698],
                            [2.195, 4.529],
                            [5.412, 1.495],
                            [6.503, 3.837],
                            [8.377, -2.251],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [158.461, 996.865],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.122, 0.08],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [1.248, -2.497],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-1.239, -2.477],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [8.819, 7.324],
                            [8.821, 7.322],
                            [19.901, 1.391],
                            [19.744, -4.853],
                            [10.537, -12.499],
                            [10.535, -12.499],
                            [10.535, -12.5],
                            [-0.122, -6.998],
                            [-10.535, -12.657],
                            [-19.746, -5.009],
                            [-19.901, 1.235],
                            [-7.882, 7.276],
                            [-6.424, 12.657],
                            [7.485, 12.657],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [158.591, 951.912],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 2",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 2,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [1.919, 8.659],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-7.806, -1.872],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-4.684, 2.497],
                            [-0.628, 0.354],
                            [0, 0],
                            [4.527, 2.029],
                            [0, 0],
                            [-3.121, -1.093],
                            [-0.285, -0.123],
                            [-3.12, 1.093],
                            [0, 0],
                            [0, 0],
                            [4.427, -1.985],
                            [0, 0],
                            [-3.323, -1.841],
                            [-0.598, -0.318],
                            [-0.419, -0.163],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [-1.872, 7.96],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.851, -0.453],
                            [2.833, -1.598],
                            [0, 0],
                            [-4.527, -2.03],
                            [0, 0],
                            [3.035, 1.063],
                            [0, 0],
                            [3.121, -1.093],
                            [0, 0],
                            [-0.08, 0.034],
                            [-4.526, 2.029],
                            [0, 0],
                            [0.484, 0.269],
                            [0.461, 0.247],
                            [3.831, 1.505],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [7.803, -1.874],
                          ],
                          v: [
                            [27.238, -11.549],
                            [20.681, -17.169],
                            [17.404, -15.764],
                            [7.883, -11.705],
                            [-6.937, -12.012],
                            [-17.404, -15.918],
                            [-17.406, -15.921],
                            [-17.761, -16.072],
                            [-20.682, -17.325],
                            [-27.238, -11.705],
                            [-17.248, -1.56],
                            [-18.81, 8.743],
                            [-18.713, 8.763],
                            [-12.253, 10.149],
                            [-18.636, 10.858],
                            [-15.722, 15.022],
                            [-13.262, 14.244],
                            [-11.16, 13.581],
                            [-12.33, 14.635],
                            [-14.282, 16.391],
                            [-7.102, 15.923],
                            [-4.891, 14.714],
                            [-1.327, 12.489],
                            [-6.79, 5.778],
                            [-13.189, 2.968],
                            [-8.507, 2.187],
                            [-0.13, 5.625],
                            [8.507, 2.344],
                            [13.19, 3.123],
                            [13.186, 3.124],
                            [6.79, 5.933],
                            [1.327, 12.645],
                            [5.482, 15.199],
                            [7.102, 16.079],
                            [8.422, 16.691],
                            [14.282, 16.547],
                            [13.256, 15.622],
                            [11.161, 13.738],
                            [15.098, 14.545],
                            [16.398, 14.813],
                            [17.989, 11.941],
                            [18.55, 10.928],
                            [12.254, 10.304],
                            [18.81, 8.898],
                            [17.248, -1.402],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [158.591, 974.686],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 3",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 3,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 10,
              ty: 4,
              nm: "LowerThirdBox",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1794.043, 969.858, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [843.231, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 28,
                      s: [0, 100, 100],
                    },
                    {
                      t: 42,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [843.231, 63.49],
                            [-843.231, 63.49],
                            [-843.231, -63.49],
                            [843.231, -63.49],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "gf",
                      o: {
                        a: 0,
                        k: 100,
                        ix: 10,
                      },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [
                            0, 0.086, 0.047, 0.243, 0.5, 0.1, 0.045, 0.259, 1,
                            0.114, 0.043, 0.275,
                          ],
                          ix: 9,
                        },
                      },
                      s: {
                        a: 0,
                        k: [-91.813, 365.534],
                        ix: 5,
                      },
                      e: {
                        a: 0,
                        k: [62.363, -251.17],
                        ix: 6,
                      },
                      t: 1,
                      nm: "Gradient Fill 1",
                      mn: "ADBE Vector Graphic - G-Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
          ],
        },
        {
          id: "comp_14",
          nm: "News Article List News 4",
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 5,
              nm: ".currentTitleList",
              cl: "currentTitleList",
              parent: 2,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [185.44, 28.997, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [182.247, -12.151, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 34.6677398681641,
                        f: "Poppins-SemiBold",
                        t: data[3].headline_snippet,
                        ca: 1,
                        j: 0,
                        tr: 0,
                        lh: 41.6012802124023,
                        ls: 0,
                        fc: [0.475, 0.694, 0.914],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 85,
              op: 1358,
              st: 51,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 5,
              nm: "4",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 85,
                      s: [1367.794, 1845.927, 0],
                      to: [0, -178.333, 0],
                      ti: [0, 178.333, 0],
                    },
                    {
                      t: 102,
                      s: [1367.794, 775.927, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [29.661, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 0.1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0.9, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 116,
                      s: [100, 100, 100],
                    },
                    {
                      i: {
                        x: [0.1, 0.1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0.9, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 126,
                      s: [136, 136, 100],
                    },
                    {
                      i: {
                        x: [0.1, 0.1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0.9, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 155,
                      s: [136, 136, 100],
                    },
                    {
                      t: 165,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 86.348747253418,
                        f: "Poppins-Bold",
                        t: "4",
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 103.618499755859,
                        ls: 0,
                        fc: [0.475, 0.694, 0.914],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 85,
              op: 1358,
              st: 51,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 5,
              nm: ".newsTitle3",
              cl: "newsTitle3",
              parent: 4,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [185.44, 28.996, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [182.247, -12.151, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 34.6677398681641,
                        f: "Poppins-SemiBold",
                        t: data[2].headline_snippet,
                        ca: 1,
                        j: 0,
                        tr: 0,
                        lh: 41.6012802124023,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 68,
              op: 1341,
              st: 34,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 4,
              ty: 5,
              nm: "3",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 68,
                      s: [1363.692, 1651.454, 0],
                      to: [0, -178.333, 0],
                      ti: [0, 178.333, 0],
                    },
                    {
                      t: 85,
                      s: [1363.692, 581.454, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [25.559, 0.518, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 86.348747253418,
                        f: "Poppins-Bold",
                        t: "3",
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 103.618499755859,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 68,
              op: 1341,
              st: 34,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 5,
              ty: 5,
              nm: ".newsTitle2",
              cl: "newsTitle2",
              parent: 6,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [185.44, 28.997, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [182.247, -12.151, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 34.6677398681641,
                        f: "Poppins-SemiBold",
                        t: data[1].headline_snippet,
                        ca: 1,
                        j: 0,
                        tr: 0,
                        lh: 41.6012802124023,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 51,
              op: 1324,
              st: 17,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 6,
              ty: 5,
              nm: "2",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 51,
                      s: [1363.088, 1455.082, 0],
                      to: [0, -178.333, 0],
                      ti: [0, 178.333, 0],
                    },
                    {
                      t: 68,
                      s: [1363.088, 385.082, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [24.955, -0.863, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 86.348747253418,
                        f: "Poppins-Bold",
                        t: "2",
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 103.618499755859,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 51,
              op: 1324,
              st: 17,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 7,
              ty: 5,
              nm: ".newsTitle1",
              cl: "newsTitle1",
              parent: 8,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [185.44, 28.997, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [182.247, -12.151, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 34.6677398681641,
                        f: "Poppins-SemiBold",
                        t: data[0].headline_snippet,
                        ca: 1,
                        j: 0,
                        tr: 0,
                        lh: 41.6012802124023,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 34,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 8,
              ty: 5,
              nm: "1",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 34,
                      s: [1352.424, 1260.955, 0],
                      to: [0, -178.333, 0],
                      ti: [0, 178.333, 0],
                    },
                    {
                      t: 51,
                      s: [1352.424, 190.955, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [14.291, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 86.348747253418,
                        f: "Poppins-Bold",
                        t: "1",
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 103.618499755859,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 34,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 9,
              ty: 4,
              nm: "Layer 5",
              td: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 0,
                      s: [144.128, 1213.321, 0],
                      to: [0, -49.667, 0],
                      ti: [0, 49.667, 0],
                    },
                    {
                      t: 15,
                      s: [144.128, 915.321, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [144.128, 936.157, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [116.407, 86.813],
                            [-116.407, 86.813],
                            [-116.407, -86.813],
                            [116.407, -86.813],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.223529413342, 0.407843142748, 0.678431391716, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [144.128, 936.157],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 10,
              ty: 5,
              nm: "2",
              tt: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 12,
                      s: [-56.254, 912.614, 0],
                      to: [31.614, 0, 0],
                      ti: [-31.614, 0, 0],
                    },
                    {
                      t: 31,
                      s: [133.428, 912.614, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [41.198, -53.886, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 142.555236816406,
                        f: "Poppins-Bold",
                        t: "4",
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 171.066284179688,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 11,
              ty: 4,
              nm: "Layer 4",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 8,
                      s: [144.128, 1213.321, 0],
                      to: [0, -49.667, 0],
                      ti: [0, 49.667, 0],
                    },
                    {
                      t: 30,
                      s: [144.128, 915.321, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [144.128, 936.157, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [116.407, 86.813],
                            [-116.407, 86.813],
                            [-116.407, -86.813],
                            [116.407, -86.813],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.223529413342, 0.407843142748, 0.678431391716, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [144.128, 936.157],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 12,
              ty: 5,
              nm: ".currentTitle",
              cl: "currentTitle",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 14,
                      s: [335.151, 1223.704, 0],
                      to: [0, -38, 0],
                      ti: [0, 38, 0],
                    },
                    {
                      t: 36,
                      s: [335.151, 995.704, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 57.2337989807129,
                        f: "Poppins-SemiBold",
                        t: data[3].headline_snippet,
                        ca: 1,
                        j: 0,
                        tr: 0,
                        lh: 68.6805572509766,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 13,
              ty: 0,
              nm: "Logo",
              refId: "comp_15",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 19,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              w: 1920,
              h: 1080,
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 14,
              ty: 4,
              nm: "Layer",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960.182, 540.221, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-960.221, 362.987],
                            [-960.221, 540],
                            [960.221, 540],
                            [960.221, -540],
                            [163.669, -540],
                            [163.669, 362.987],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0, 0, 0, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 15,
              ty: 2,
              nm: ".ImageList4",
              cl: "ImageList4",
              refId: "ListImage4",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [176, 108, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [716.466, 251.749, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.833, 0.833, 0.833],
                        y: [0.833, 0.833, 0.833],
                      },
                      o: {
                        x: [0.167, 0.167, 0.167],
                        y: [0.167, 0.167, 0.167],
                      },
                      t: 0,
                      s: [48.463, 48.463, 100],
                    },
                    {
                      t: 301,
                      s: [62.193, 62.193, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
          ],
        },
        {
          id: "comp_15",
          nm: "Logo",
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 4,
              nm: "Layer 3",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1512.252, 785.896, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [14.126, 38.851],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-14.126, 38.851],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [0.575, -75.484],
                            [0.006, -75.786],
                            [-0.197, -75.669],
                            [-0.219, -75.657],
                            [-35.879, -56.521],
                            [-94.777, -24.872],
                            [-73.578, 44.012],
                            [-61.225, 17.5],
                            [-0.002, 75.786],
                            [24.838, 51.248],
                            [61.232, 16.916],
                            [73.582, 43.417],
                            [94.777, -25.465],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 4,
              nm: "Layer 2",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1513.727, 277.278, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [1.375, 0.901],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [14.118, -28.255],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-14.023, -28.02],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [99.781, 82.862],
                            [99.804, 82.844],
                            [225.169, 15.742],
                            [223.389, -54.911],
                            [119.224, -141.422],
                            [119.201, -141.422],
                            [119.194, -141.43],
                            [-1.381, -79.174],
                            [-119.201, -143.202],
                            [-223.407, -56.669],
                            [-225.169, 13.974],
                            [-89.178, 82.325],
                            [-72.679, 143.202],
                            [84.689, 143.202],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 4,
              nm: "Layer 1",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1513.719, 534.949, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [21.707, 97.974],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-88.316, -21.181],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-52.995, 28.248],
                            [-7.107, 4.003],
                            [0, 0],
                            [51.214, 22.954],
                            [0, 0],
                            [-35.317, -12.364],
                            [-3.228, -1.39],
                            [-35.303, 12.365],
                            [0, 0],
                            [0, 0],
                            [50.09, -22.461],
                            [0, 0],
                            [-37.601, -20.831],
                            [-6.765, -3.599],
                            [-4.736, -1.842],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [-21.184, 90.062],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [9.625, -5.129],
                            [32.048, -18.075],
                            [0, 0],
                            [-51.215, -22.968],
                            [0, 0],
                            [34.34, 12.03],
                            [0, 0],
                            [35.31, -12.368],
                            [0, 0],
                            [-0.904, 0.386],
                            [-51.211, 22.953],
                            [0, 0],
                            [5.471, 3.044],
                            [5.213, 2.79],
                            [43.348, 17.03],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [88.291, -21.2],
                          ],
                          v: [
                            [308.179, -130.667],
                            [233.993, -194.251],
                            [196.917, -178.352],
                            [89.185, -132.432],
                            [-78.483, -135.91],
                            [-196.917, -180.103],
                            [-196.939, -180.129],
                            [-200.947, -181.842],
                            [-233.996, -196.015],
                            [-308.179, -132.432],
                            [-195.149, -17.645],
                            [-212.823, 98.919],
                            [-211.727, 99.15],
                            [-138.633, 114.824],
                            [-210.852, 122.854],
                            [-177.887, 169.959],
                            [-150.048, 161.164],
                            [-126.267, 153.664],
                            [-139.507, 165.579],
                            [-161.592, 185.448],
                            [-80.354, 180.161],
                            [-55.338, 166.473],
                            [-15.013, 141.307],
                            [-76.824, 65.37],
                            [-149.225, 33.578],
                            [-96.252, 24.739],
                            [-1.469, 63.638],
                            [96.252, 26.515],
                            [149.232, 35.336],
                            [149.195, 35.346],
                            [76.824, 67.131],
                            [15.01, 143.064],
                            [62.026, 171.962],
                            [80.358, 181.922],
                            [95.289, 188.845],
                            [161.586, 187.22],
                            [149.982, 176.757],
                            [126.275, 155.433],
                            [170.828, 164.565],
                            [185.527, 167.594],
                            [203.528, 135.1],
                            [209.874, 123.647],
                            [138.643, 116.578],
                            [212.816, 100.68],
                            [195.145, -15.868],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
          ],
        },
        {
          id: "comp_16",
          nm: "Mexem News Article 3",
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 0,
              nm: ".NewsDescription3",
              cl: "NewsDescription3",
              refId: "comp_17",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              w: 1920,
              h: 1080,
              ip: 118,
              op: 1425,
              st: 118,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 0,
              nm: ".NewsLower3",
              cl: "NewsLower3",
              refId: "comp_18",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              w: 1920,
              h: 1080,
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 4,
              nm: "BGMask",
              td: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 4,
              ty: 2,
              nm: ".ImageNews3",
              cl: "ImageNews3",
              tt: 2,
              refId: "ArticleImage3",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1452, 292, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [2218.651, 532.377, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.833, 0.833, 0.833],
                        y: [0.833, 0.833, 0.833],
                      },
                      o: {
                        x: [0.167, 0.167, 0.167],
                        y: [0.167, 0.167, 0.167],
                      },
                      t: 0,
                      s: [66.792, 66.792, 100],
                    },
                    {
                      t: 305,
                      s: [82.338, 82.338, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
          ],
        },
        {
          id: "comp_17",
          nm: "DescriptionBox News 3",
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 4,
              nm: "DescriptionBG 2",
              td: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 90,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 13,
                      s: [1436.647, 289.305, 0],
                      to: [-54.125, -18.792, 0],
                      ti: [54.125, 18.792, 0],
                    },
                    {
                      t: 25,
                      s: [1111.897, 176.555, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [-344.595, -146.509, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 0.1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0.9, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 13,
                      s: [6.702, 15.619, 100],
                    },
                    {
                      t: 25,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [344.595, 146.509],
                            [-344.595, 146.509],
                            [-344.595, -146.509],
                            [344.595, -146.509],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "gf",
                      o: {
                        a: 0,
                        k: 100,
                        ix: 10,
                      },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [
                            0, 0.086, 0.047, 0.243, 0.5, 0.1, 0.045, 0.259, 1,
                            0.114, 0.043, 0.275,
                          ],
                          ix: 9,
                        },
                      },
                      s: {
                        a: 0,
                        k: [-345.492, -0.672],
                        ix: 5,
                      },
                      e: {
                        a: 0,
                        k: [343.699, -0.672],
                        ix: 6,
                      },
                      t: 1,
                      nm: "Gradient Fill 1",
                      mn: "ADBE Vector Graphic - G-Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 5,
              nm: ".NewsDescription2",
              cl: "NewsDescription2",
              tt: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1451.583, 310.336, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [-5.493, -26.604, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        sz: [596.73046875, 223.542282104492],
                        ps: [-298.365234375, -111.771141052246],
                        s: 29.9096698760986,
                        f: "Poppins-SemiBold",
                        t: data[2].description,
                        ca: 1,
                        j: 0,
                        tr: 0,
                        lh: 35.8916091918945,
                        ls: 0,
                        fc: [0.949, 0.949, 0.949],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 4,
              nm: "DescriptionSquareHalf2",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 13,
                      s: [1459.354, 311.778, 0],
                      to: [53, 22.75, 0],
                      ti: [-53, -22.75, 0],
                    },
                    {
                      t: 25,
                      s: [1777.354, 448.278, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 0.1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0.9, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 0,
                      s: [0, 0, 100],
                    },
                    {
                      t: 6,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [23.408, -23.408],
                            [23.408, 23.408],
                            [-23.408, 23.408],
                          ],
                          c: false,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.956862747669, 0.956862747669, 0.956862747669, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 4,
              ty: 4,
              nm: "DescriptionSquareHalf1",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 13,
                      s: [1459.867, 312.086, 0],
                      to: [-54.094, -18.687, 0],
                      ti: [54.094, 18.687, 0],
                    },
                    {
                      t: 25,
                      s: [1135.304, 199.962, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 0.1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0.9, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 0,
                      s: [0, 0, 100],
                    },
                    {
                      t: 6,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-23.408, 23.408],
                            [-23.408, -23.408],
                            [23.408, -23.408],
                          ],
                          c: false,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.956862747669, 0.956862747669, 0.956862747669, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 5,
              ty: 4,
              nm: "DescriptionBG",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 90,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 13,
                      s: [1436.647, 289.305, 0],
                      to: [-54.125, -18.792, 0],
                      ti: [54.125, 18.792, 0],
                    },
                    {
                      t: 25,
                      s: [1111.897, 176.555, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [-344.595, -146.509, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 0.1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0.9, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 13,
                      s: [6.702, 15.619, 100],
                    },
                    {
                      t: 25,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [344.595, 146.509],
                            [-344.595, 146.509],
                            [-344.595, -146.509],
                            [344.595, -146.509],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "gf",
                      o: {
                        a: 0,
                        k: 100,
                        ix: 10,
                      },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [
                            0, 0.086, 0.047, 0.243, 0.5, 0.1, 0.045, 0.259, 1,
                            0.114, 0.043, 0.275,
                          ],
                          ix: 9,
                        },
                      },
                      s: {
                        a: 0,
                        k: [-345.492, -0.672],
                        ix: 5,
                      },
                      e: {
                        a: 0,
                        k: [343.699, -0.672],
                        ix: 6,
                      },
                      t: 1,
                      nm: "Gradient Fill 1",
                      mn: "ADBE Vector Graphic - G-Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
          ],
        },
        {
          id: "comp_18",
          nm: "Lower Third News 3",
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 4,
              nm: "HeadlineBox 2",
              td: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [83.282, 885.028, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [83.282, 880.267, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 45,
                      s: [0, 100, 100],
                    },
                    {
                      t: 56,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [165.902, 34.873],
                            [-165.902, 34.873],
                            [-165.902, -34.873],
                            [165.902, -34.873],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.227450981736, 0.407843142748, 0.68235296011, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [249.184, 880.267],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 5,
              nm: "HeadlineText",
              tt: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 63,
                      s: [-214.275, 886.46, 0],
                      to: [57.333, 0, 0],
                      ti: [-57.333, 0, 0],
                    },
                    {
                      t: 74,
                      s: [129.725, 886.46, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [3.233, -16.42, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 46.8478507995605,
                        f: "Poppins-SemiBold",
                        t: "HEADLINES",
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 56.2174301147461,
                        ls: 0,
                        fc: [0.949, 0.949, 0.949],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 4,
              nm: "HeadlineBox",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [83.282, 885.028, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [83.282, 880.267, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 52,
                      s: [0, 100, 100],
                    },
                    {
                      t: 63,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [165.902, 34.873],
                            [-165.902, 34.873],
                            [-165.902, -34.873],
                            [165.902, -34.873],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.227450981736, 0.407843142748, 0.68235296011, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [249.184, 880.267],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 4,
              ty: 5,
              nm: ".NewsHealineNumber3",
              cl: "NewsHealineNumber3",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 0,
                      s: [126.696, 1284.042, 0],
                      to: [0, -48, 0],
                      ti: [0, 48, 0],
                    },
                    {
                      i: {
                        x: 0.1,
                        y: 0.1,
                      },
                      o: {
                        x: 0.9,
                        y: 0.9,
                      },
                      t: 15,
                      s: [126.696, 996.042, 0],
                      to: [0, 0, 0],
                      ti: [0, 0, 0],
                    },
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 30,
                      s: [126.696, 996.042, 0],
                      to: [249, 0, 0],
                      ti: [-249, 0, 0],
                    },
                    {
                      t: 44,
                      s: [1620.696, 996.042, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 200.433181762695,
                        f: "Poppins-Bold",
                        t: "3",
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 240.519821166992,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 5,
              ty: 4,
              nm: "LowerThirdLine",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [950.524, 1033.348, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-843.519, 0],
                            [843.519, 0],
                          ],
                          c: false,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "st",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 3,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 4,
                      },
                      w: {
                        a: 0,
                        k: 19,
                        ix: 5,
                      },
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "tm",
                  s: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.1],
                          y: [1],
                        },
                        o: {
                          x: [0.9],
                          y: [0],
                        },
                        t: 30,
                        s: [100],
                      },
                      {
                        t: 44,
                        s: [0],
                      },
                    ],
                    ix: 1,
                  },
                  e: {
                    a: 0,
                    k: 100,
                    ix: 2,
                  },
                  o: {
                    a: 0,
                    k: 0,
                    ix: 3,
                  },
                  m: 1,
                  ix: 2,
                  nm: "Trim Paths 1",
                  mn: "ADBE Vector Filter - Trim",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 6,
              ty: 4,
              nm: "LowerThirdBox 3",
              td: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1794.043, 969.858, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [843.231, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 35,
                      s: [0, 100, 100],
                    },
                    {
                      t: 49,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [843.231, 63.49],
                            [-843.231, 63.49],
                            [-843.231, -63.49],
                            [843.231, -63.49],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "gf",
                      o: {
                        a: 0,
                        k: 100,
                        ix: 10,
                      },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [
                            0, 0.086, 0.047, 0.243, 0.5, 0.1, 0.045, 0.259, 1,
                            0.114, 0.043, 0.275,
                          ],
                          ix: 9,
                        },
                      },
                      s: {
                        a: 0,
                        k: [-91.813, 365.534],
                        ix: 5,
                      },
                      e: {
                        a: 0,
                        k: [62.363, -251.17],
                        ix: 6,
                      },
                      t: 1,
                      nm: "Gradient Fill 1",
                      mn: "ADBE Vector Graphic - G-Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 7,
              ty: 5,
              nm: ".NewsHeadline3",
              cl: "NewsHeadline3",
              tt: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.25,
                        y: 1,
                      },
                      o: {
                        x: 0.75,
                        y: 0,
                      },
                      t: 78,
                      s: [274.591, 1171.409, 0],
                      to: [0, -30.333, 0],
                      ti: [0, 30.333, 0],
                    },
                    {
                      t: 104,
                      s: [274.591, 989.409, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 35,
                        f: "Poppins-SemiBold",
                        t: data[2].headline,
                        ca: 1,
                        j: 0,
                        tr: 0,
                        lh: 68.6805572509766,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 8,
              ty: 4,
              nm: "LowerThirdBox 2",
              td: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1794.043, 969.858, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [843.231, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 35,
                      s: [0, 100, 100],
                    },
                    {
                      t: 49,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [843.231, 63.49],
                            [-843.231, 63.49],
                            [-843.231, -63.49],
                            [843.231, -63.49],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "gf",
                      o: {
                        a: 0,
                        k: 100,
                        ix: 10,
                      },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [
                            0, 0.086, 0.047, 0.243, 0.5, 0.1, 0.045, 0.259, 1,
                            0.114, 0.043, 0.275,
                          ],
                          ix: 9,
                        },
                      },
                      s: {
                        a: 0,
                        k: [-91.813, 365.534],
                        ix: 5,
                      },
                      e: {
                        a: 0,
                        k: [62.363, -251.17],
                        ix: 6,
                      },
                      t: 1,
                      nm: "Gradient Fill 1",
                      mn: "ADBE Vector Graphic - G-Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 9,
              ty: 4,
              nm: "Logo",
              tt: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.25,
                        y: 1,
                      },
                      o: {
                        x: 0.75,
                        y: 0,
                      },
                      t: 52,
                      s: [158.591, 1097.409, 0],
                      to: [0, -21, 0],
                      ti: [0, 21, 0],
                    },
                    {
                      t: 78,
                      s: [158.591, 971.409, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [158.591, 971.409, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [1.249, 3.434],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-1.249, 3.434],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [0.051, -6.672],
                            [0, -6.698],
                            [-0.017, -6.688],
                            [-0.019, -6.687],
                            [-3.171, -4.996],
                            [-8.377, -2.198],
                            [-6.503, 3.89],
                            [-5.411, 1.547],
                            [0, 6.698],
                            [2.195, 4.529],
                            [5.412, 1.495],
                            [6.503, 3.837],
                            [8.377, -2.251],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [158.461, 996.865],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.122, 0.08],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [1.248, -2.497],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-1.239, -2.477],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [8.819, 7.324],
                            [8.821, 7.322],
                            [19.901, 1.391],
                            [19.744, -4.853],
                            [10.537, -12.499],
                            [10.535, -12.499],
                            [10.535, -12.5],
                            [-0.122, -6.998],
                            [-10.535, -12.657],
                            [-19.746, -5.009],
                            [-19.901, 1.235],
                            [-7.882, 7.276],
                            [-6.424, 12.657],
                            [7.485, 12.657],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [158.591, 951.912],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 2",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 2,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [1.919, 8.659],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-7.806, -1.872],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-4.684, 2.497],
                            [-0.628, 0.354],
                            [0, 0],
                            [4.527, 2.029],
                            [0, 0],
                            [-3.121, -1.093],
                            [-0.285, -0.123],
                            [-3.12, 1.093],
                            [0, 0],
                            [0, 0],
                            [4.427, -1.985],
                            [0, 0],
                            [-3.323, -1.841],
                            [-0.598, -0.318],
                            [-0.419, -0.163],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [-1.872, 7.96],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.851, -0.453],
                            [2.833, -1.598],
                            [0, 0],
                            [-4.527, -2.03],
                            [0, 0],
                            [3.035, 1.063],
                            [0, 0],
                            [3.121, -1.093],
                            [0, 0],
                            [-0.08, 0.034],
                            [-4.526, 2.029],
                            [0, 0],
                            [0.484, 0.269],
                            [0.461, 0.247],
                            [3.831, 1.505],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [7.803, -1.874],
                          ],
                          v: [
                            [27.238, -11.549],
                            [20.681, -17.169],
                            [17.404, -15.764],
                            [7.883, -11.705],
                            [-6.937, -12.012],
                            [-17.404, -15.918],
                            [-17.406, -15.921],
                            [-17.761, -16.072],
                            [-20.682, -17.325],
                            [-27.238, -11.705],
                            [-17.248, -1.56],
                            [-18.81, 8.743],
                            [-18.713, 8.763],
                            [-12.253, 10.149],
                            [-18.636, 10.858],
                            [-15.722, 15.022],
                            [-13.262, 14.244],
                            [-11.16, 13.581],
                            [-12.33, 14.635],
                            [-14.282, 16.391],
                            [-7.102, 15.923],
                            [-4.891, 14.714],
                            [-1.327, 12.489],
                            [-6.79, 5.778],
                            [-13.189, 2.968],
                            [-8.507, 2.187],
                            [-0.13, 5.625],
                            [8.507, 2.344],
                            [13.19, 3.123],
                            [13.186, 3.124],
                            [6.79, 5.933],
                            [1.327, 12.645],
                            [5.482, 15.199],
                            [7.102, 16.079],
                            [8.422, 16.691],
                            [14.282, 16.547],
                            [13.256, 15.622],
                            [11.161, 13.738],
                            [15.098, 14.545],
                            [16.398, 14.813],
                            [17.989, 11.941],
                            [18.55, 10.928],
                            [12.254, 10.304],
                            [18.81, 8.898],
                            [17.248, -1.402],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [158.591, 974.686],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 3",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 3,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 10,
              ty: 4,
              nm: "LowerThirdBox",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1794.043, 969.858, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [843.231, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 28,
                      s: [0, 100, 100],
                    },
                    {
                      t: 42,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [843.231, 63.49],
                            [-843.231, 63.49],
                            [-843.231, -63.49],
                            [843.231, -63.49],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "gf",
                      o: {
                        a: 0,
                        k: 100,
                        ix: 10,
                      },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [
                            0, 0.086, 0.047, 0.243, 0.5, 0.1, 0.045, 0.259, 1,
                            0.114, 0.043, 0.275,
                          ],
                          ix: 9,
                        },
                      },
                      s: {
                        a: 0,
                        k: [-91.813, 365.534],
                        ix: 5,
                      },
                      e: {
                        a: 0,
                        k: [62.363, -251.17],
                        ix: 6,
                      },
                      t: 1,
                      nm: "Gradient Fill 1",
                      mn: "ADBE Vector Graphic - G-Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
          ],
        },
        {
          id: "comp_19",
          nm: "News Article List News 3",
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 5,
              nm: ".newsTitle4",
              cl: "newsTitle4",
              parent: 2,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [185.44, 28.997, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [182.247, -12.151, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 34.6677398681641,
                        f: "Poppins-SemiBold",
                        t: data[3].headline_snippet,
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 41.6012802124023,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 85,
              op: 1358,
              st: 51,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 5,
              nm: "4",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 85,
                      s: [1367.794, 1845.927, 0],
                      to: [0, -178.333, 0],
                      ti: [0, 178.333, 0],
                    },
                    {
                      t: 102,
                      s: [1367.794, 775.927, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [29.661, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 86.348747253418,
                        f: "Poppins-Bold",
                        t: "4",
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 103.618499755859,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 85,
              op: 1358,
              st: 51,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 5,
              nm: ".currentTitleList",
              cl: "currentTitleList",
              parent: 4,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [185.44, 28.996, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [182.247, -12.151, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 34.6677398681641,
                        f: "Poppins-SemiBold",
                        t: data[2].headline_snippet,
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 41.6012802124023,
                        ls: 0,
                        fc: [0.475, 0.694, 0.914],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 68,
              op: 1341,
              st: 34,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 4,
              ty: 5,
              nm: "3",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 68,
                      s: [1363.692, 1651.454, 0],
                      to: [0, -178.333, 0],
                      ti: [0, 178.333, 0],
                    },
                    {
                      t: 85,
                      s: [1363.692, 581.454, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [25.559, 0.518, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 0.1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0.9, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 116,
                      s: [100, 100, 100],
                    },
                    {
                      i: {
                        x: [0.1, 0.1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0.9, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 126,
                      s: [136, 136, 100],
                    },
                    {
                      i: {
                        x: [0.1, 0.1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0.9, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 155,
                      s: [136, 136, 100],
                    },
                    {
                      t: 165,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 86.348747253418,
                        f: "Poppins-Bold",
                        t: "3",
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 103.618499755859,
                        ls: 0,
                        fc: [0.475, 0.694, 0.914],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 68,
              op: 1341,
              st: 34,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 5,
              ty: 5,
              nm: ".newsTitle2",
              cl: "newsTitle2",
              parent: 6,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [185.44, 28.997, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [182.247, -12.151, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 34.6677398681641,
                        f: "Poppins-SemiBold",
                        t: data[1].headline_snippet,
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 41.6012802124023,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 51,
              op: 1324,
              st: 17,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 6,
              ty: 5,
              nm: "2",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 51,
                      s: [1363.088, 1455.082, 0],
                      to: [0, -178.333, 0],
                      ti: [0, 178.333, 0],
                    },
                    {
                      t: 68,
                      s: [1363.088, 385.082, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [24.955, -0.863, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 86.348747253418,
                        f: "Poppins-Bold",
                        t: "2",
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 103.618499755859,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 51,
              op: 1324,
              st: 17,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 7,
              ty: 5,
              nm: ".newsTitle1",
              cl: "newsTitle1",
              parent: 8,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [185.44, 28.997, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [182.247, -12.151, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 34.6677398681641,
                        f: "Poppins-SemiBold",
                        t: data[0].headline_snippet,
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 41.6012802124023,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 34,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 8,
              ty: 5,
              nm: "1",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 34,
                      s: [1352.424, 1260.955, 0],
                      to: [0, -178.333, 0],
                      ti: [0, 178.333, 0],
                    },
                    {
                      t: 51,
                      s: [1352.424, 190.955, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [14.291, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 86.348747253418,
                        f: "Poppins-Bold",
                        t: "1",
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 103.618499755859,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 34,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 9,
              ty: 4,
              nm: "Layer 5",
              td: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 0,
                      s: [144.128, 1213.321, 0],
                      to: [0, -49.667, 0],
                      ti: [0, 49.667, 0],
                    },
                    {
                      t: 15,
                      s: [144.128, 915.321, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [144.128, 936.157, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [116.407, 86.813],
                            [-116.407, 86.813],
                            [-116.407, -86.813],
                            [116.407, -86.813],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.223529413342, 0.407843142748, 0.678431391716, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [144.128, 936.157],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 10,
              ty: 5,
              nm: "2",
              tt: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 12,
                      s: [-56.254, 912.614, 0],
                      to: [34, 0, 0],
                      ti: [-34, 0, 0],
                    },
                    {
                      t: 31,
                      s: [147.746, 912.614, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [41.198, -53.886, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 142.555236816406,
                        f: "Poppins-Bold",
                        t: "3",
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 171.066284179688,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 11,
              ty: 4,
              nm: "Layer 4",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 8,
                      s: [144.128, 1213.321, 0],
                      to: [0, -49.667, 0],
                      ti: [0, 49.667, 0],
                    },
                    {
                      t: 30,
                      s: [144.128, 915.321, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [144.128, 936.157, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [116.407, 86.813],
                            [-116.407, 86.813],
                            [-116.407, -86.813],
                            [116.407, -86.813],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.223529413342, 0.407843142748, 0.678431391716, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [144.128, 936.157],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 12,
              ty: 5,
              nm: ".currentTitle",
              cl: "currentTitle",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 14,
                      s: [335.151, 1223.704, 0],
                      to: [0, -38, 0],
                      ti: [0, 38, 0],
                    },
                    {
                      t: 36,
                      s: [335.151, 995.704, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 57.2337989807129,
                        f: "Poppins-SemiBold",
                        t: data[2].headline_snippet,
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 68.6805572509766,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 13,
              ty: 0,
              nm: "Logo",
              refId: "comp_15",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 19,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              w: 1920,
              h: 1080,
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 14,
              ty: 4,
              nm: "Layer",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960.182, 540.221, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-960.221, 362.987],
                            [-960.221, 540],
                            [960.221, 540],
                            [960.221, -540],
                            [163.669, -540],
                            [163.669, 362.987],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0, 0, 0, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 15,
              ty: 2,
              nm: ".ImageList3",
              cl: "ImageList3",
              refId: "ListImage3",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [176, 108, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [716.466, 251.749, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.833, 0.833, 0.833],
                        y: [0.833, 0.833, 0.833],
                      },
                      o: {
                        x: [0.167, 0.167, 0.167],
                        y: [0.167, 0.167, 0.167],
                      },
                      t: 0,
                      s: [48.463, 48.463, 100],
                    },
                    {
                      t: 301,
                      s: [62.193, 62.193, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
          ],
        },
        {
          id: "comp_20",
          nm: "Mexem News Article 2",
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 0,
              nm: ".NewsDescription2",
              cl: "NewsDescription2",
              refId: "comp_21",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              w: 1920,
              h: 1080,
              ip: 118,
              op: 1425,
              st: 118,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 0,
              nm: ".NewsLower2",
              cl: "NewsLower2",
              refId: "comp_22",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              w: 1920,
              h: 1080,
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 4,
              nm: "BGMask",
              td: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 4,
              ty: 2,
              nm: ".ImageNews2",
              cl: "ImageNews2",
              tt: 2,
              refId: "ArticleImage2",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1452, 292, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [2218.651, 532.377, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.833, 0.833, 0.833],
                        y: [0.833, 0.833, 0.833],
                      },
                      o: {
                        x: [0.167, 0.167, 0.167],
                        y: [0.167, 0.167, 0.167],
                      },
                      t: 0,
                      s: [66.792, 66.792, 100],
                    },
                    {
                      t: 305,
                      s: [82.338, 82.338, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
          ],
        },
        {
          id: "comp_21",
          nm: "DescriptionBox News 2",
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 4,
              nm: "DescriptionBG 2",
              td: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 90,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 13,
                      s: [1436.647, 289.305, 0],
                      to: [-54.125, -18.792, 0],
                      ti: [54.125, 18.792, 0],
                    },
                    {
                      t: 25,
                      s: [1111.897, 176.555, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [-344.595, -146.509, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 0.1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0.9, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 13,
                      s: [6.702, 15.619, 100],
                    },
                    {
                      t: 25,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [344.595, 146.509],
                            [-344.595, 146.509],
                            [-344.595, -146.509],
                            [344.595, -146.509],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "gf",
                      o: {
                        a: 0,
                        k: 100,
                        ix: 10,
                      },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [
                            0, 0.086, 0.047, 0.243, 0.5, 0.1, 0.045, 0.259, 1,
                            0.114, 0.043, 0.275,
                          ],
                          ix: 9,
                        },
                      },
                      s: {
                        a: 0,
                        k: [-345.492, -0.672],
                        ix: 5,
                      },
                      e: {
                        a: 0,
                        k: [343.699, -0.672],
                        ix: 6,
                      },
                      t: 1,
                      nm: "Gradient Fill 1",
                      mn: "ADBE Vector Graphic - G-Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 5,
              nm: ".NewsDescription2",
              cl: "NewsDescription2",
              tt: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1451.583, 310.336, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [-5.493, -26.604, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        sz: [596.73046875, 223.542282104492],
                        ps: [-298.365234375, -111.771141052246],
                        s: 29.9096698760986,
                        f: "Poppins-SemiBold",
                        t: data[1].description,
                        ca: 1,
                        j: 0,
                        tr: 0,
                        lh: 35.8916091918945,
                        ls: 0,
                        fc: [0.949, 0.949, 0.949],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 4,
              nm: "DescriptionSquareHalf2",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 13,
                      s: [1459.354, 311.778, 0],
                      to: [53, 22.75, 0],
                      ti: [-53, -22.75, 0],
                    },
                    {
                      t: 25,
                      s: [1777.354, 448.278, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 0.1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0.9, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 0,
                      s: [0, 0, 100],
                    },
                    {
                      t: 6,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [23.408, -23.408],
                            [23.408, 23.408],
                            [-23.408, 23.408],
                          ],
                          c: false,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.956862747669, 0.956862747669, 0.956862747669, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 4,
              ty: 4,
              nm: "DescriptionSquareHalf1",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 13,
                      s: [1459.867, 312.086, 0],
                      to: [-54.094, -18.687, 0],
                      ti: [54.094, 18.687, 0],
                    },
                    {
                      t: 25,
                      s: [1135.304, 199.962, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 0.1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0.9, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 0,
                      s: [0, 0, 100],
                    },
                    {
                      t: 6,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-23.408, 23.408],
                            [-23.408, -23.408],
                            [23.408, -23.408],
                          ],
                          c: false,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.956862747669, 0.956862747669, 0.956862747669, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 5,
              ty: 4,
              nm: "DescriptionBG",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 90,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 13,
                      s: [1436.647, 289.305, 0],
                      to: [-54.125, -18.792, 0],
                      ti: [54.125, 18.792, 0],
                    },
                    {
                      t: 25,
                      s: [1111.897, 176.555, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [-344.595, -146.509, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 0.1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0.9, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 13,
                      s: [6.702, 15.619, 100],
                    },
                    {
                      t: 25,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [344.595, 146.509],
                            [-344.595, 146.509],
                            [-344.595, -146.509],
                            [344.595, -146.509],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "gf",
                      o: {
                        a: 0,
                        k: 100,
                        ix: 10,
                      },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [
                            0, 0.086, 0.047, 0.243, 0.5, 0.1, 0.045, 0.259, 1,
                            0.114, 0.043, 0.275,
                          ],
                          ix: 9,
                        },
                      },
                      s: {
                        a: 0,
                        k: [-345.492, -0.672],
                        ix: 5,
                      },
                      e: {
                        a: 0,
                        k: [343.699, -0.672],
                        ix: 6,
                      },
                      t: 1,
                      nm: "Gradient Fill 1",
                      mn: "ADBE Vector Graphic - G-Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
          ],
        },
        {
          id: "comp_22",
          nm: "Lower Third News 2",
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 4,
              nm: "HeadlineBox 2",
              td: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [83.282, 885.028, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [83.282, 880.267, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 45,
                      s: [0, 100, 100],
                    },
                    {
                      t: 56,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [165.902, 34.873],
                            [-165.902, 34.873],
                            [-165.902, -34.873],
                            [165.902, -34.873],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.227450981736, 0.407843142748, 0.68235296011, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [249.184, 880.267],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 5,
              nm: "HeadlineText",
              tt: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 63,
                      s: [-214.275, 886.46, 0],
                      to: [57.333, 0, 0],
                      ti: [-57.333, 0, 0],
                    },
                    {
                      t: 74,
                      s: [129.725, 886.46, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [3.233, -16.42, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 46.8478507995605,
                        f: "Poppins-SemiBold",
                        t: "HEADLINES",
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 56.2174301147461,
                        ls: 0,
                        fc: [0.949, 0.949, 0.949],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 4,
              nm: "HeadlineBox",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [83.282, 885.028, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [83.282, 880.267, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 52,
                      s: [0, 100, 100],
                    },
                    {
                      t: 63,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [165.902, 34.873],
                            [-165.902, 34.873],
                            [-165.902, -34.873],
                            [165.902, -34.873],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.227450981736, 0.407843142748, 0.68235296011, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [249.184, 880.267],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 4,
              ty: 5,
              nm: ".NewsHealineNumber2",
              cl: "NewsHealineNumber2",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 0,
                      s: [126.696, 1284.042, 0],
                      to: [0, -48, 0],
                      ti: [0, 48, 0],
                    },
                    {
                      i: {
                        x: 0.1,
                        y: 0.1,
                      },
                      o: {
                        x: 0.9,
                        y: 0.9,
                      },
                      t: 15,
                      s: [126.696, 996.042, 0],
                      to: [0, 0, 0],
                      ti: [0, 0, 0],
                    },
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 30,
                      s: [126.696, 996.042, 0],
                      to: [249, 0, 0],
                      ti: [-249, 0, 0],
                    },
                    {
                      t: 44,
                      s: [1620.696, 996.042, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 200.433181762695,
                        f: "Poppins-Bold",
                        t: "2",
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 240.519821166992,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 5,
              ty: 4,
              nm: "LowerThirdLine",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [950.524, 1033.348, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-843.519, 0],
                            [843.519, 0],
                          ],
                          c: false,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "st",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 3,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 4,
                      },
                      w: {
                        a: 0,
                        k: 19,
                        ix: 5,
                      },
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "tm",
                  s: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.1],
                          y: [1],
                        },
                        o: {
                          x: [0.9],
                          y: [0],
                        },
                        t: 30,
                        s: [100],
                      },
                      {
                        t: 44,
                        s: [0],
                      },
                    ],
                    ix: 1,
                  },
                  e: {
                    a: 0,
                    k: 100,
                    ix: 2,
                  },
                  o: {
                    a: 0,
                    k: 0,
                    ix: 3,
                  },
                  m: 1,
                  ix: 2,
                  nm: "Trim Paths 1",
                  mn: "ADBE Vector Filter - Trim",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 6,
              ty: 4,
              nm: "LowerThirdBox 3",
              td: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1794.043, 969.858, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [843.231, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 35,
                      s: [0, 100, 100],
                    },
                    {
                      t: 49,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [843.231, 63.49],
                            [-843.231, 63.49],
                            [-843.231, -63.49],
                            [843.231, -63.49],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "gf",
                      o: {
                        a: 0,
                        k: 100,
                        ix: 10,
                      },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [
                            0, 0.086, 0.047, 0.243, 0.5, 0.1, 0.045, 0.259, 1,
                            0.114, 0.043, 0.275,
                          ],
                          ix: 9,
                        },
                      },
                      s: {
                        a: 0,
                        k: [-91.813, 365.534],
                        ix: 5,
                      },
                      e: {
                        a: 0,
                        k: [62.363, -251.17],
                        ix: 6,
                      },
                      t: 1,
                      nm: "Gradient Fill 1",
                      mn: "ADBE Vector Graphic - G-Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 7,
              ty: 5,
              nm: ".NewsHeadline2",
              cl: "NewsHeadline2",
              tt: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.25,
                        y: 1,
                      },
                      o: {
                        x: 0.75,
                        y: 0,
                      },
                      t: 78,
                      s: [274.591, 1171.409, 0],
                      to: [0, -30.333, 0],
                      ti: [0, 30.333, 0],
                    },
                    {
                      t: 104,
                      s: [274.591, 989.409, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 35,
                        f: "Poppins-SemiBold",
                        t: data[1].headline,
                        ca: 1,
                        j: 0,
                        tr: 0,
                        lh: 68.6805572509766,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 8,
              ty: 4,
              nm: "LowerThirdBox 2",
              td: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1794.043, 969.858, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [843.231, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 35,
                      s: [0, 100, 100],
                    },
                    {
                      t: 49,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [843.231, 63.49],
                            [-843.231, 63.49],
                            [-843.231, -63.49],
                            [843.231, -63.49],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "gf",
                      o: {
                        a: 0,
                        k: 100,
                        ix: 10,
                      },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [
                            0, 0.086, 0.047, 0.243, 0.5, 0.1, 0.045, 0.259, 1,
                            0.114, 0.043, 0.275,
                          ],
                          ix: 9,
                        },
                      },
                      s: {
                        a: 0,
                        k: [-91.813, 365.534],
                        ix: 5,
                      },
                      e: {
                        a: 0,
                        k: [62.363, -251.17],
                        ix: 6,
                      },
                      t: 1,
                      nm: "Gradient Fill 1",
                      mn: "ADBE Vector Graphic - G-Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 9,
              ty: 4,
              nm: "Logo",
              tt: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.25,
                        y: 1,
                      },
                      o: {
                        x: 0.75,
                        y: 0,
                      },
                      t: 52,
                      s: [158.591, 1097.409, 0],
                      to: [0, -21, 0],
                      ti: [0, 21, 0],
                    },
                    {
                      t: 78,
                      s: [158.591, 971.409, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [158.591, 971.409, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [1.249, 3.434],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-1.249, 3.434],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [0.051, -6.672],
                            [0, -6.698],
                            [-0.017, -6.688],
                            [-0.019, -6.687],
                            [-3.171, -4.996],
                            [-8.377, -2.198],
                            [-6.503, 3.89],
                            [-5.411, 1.547],
                            [0, 6.698],
                            [2.195, 4.529],
                            [5.412, 1.495],
                            [6.503, 3.837],
                            [8.377, -2.251],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [158.461, 996.865],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.122, 0.08],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [1.248, -2.497],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-1.239, -2.477],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [8.819, 7.324],
                            [8.821, 7.322],
                            [19.901, 1.391],
                            [19.744, -4.853],
                            [10.537, -12.499],
                            [10.535, -12.499],
                            [10.535, -12.5],
                            [-0.122, -6.998],
                            [-10.535, -12.657],
                            [-19.746, -5.009],
                            [-19.901, 1.235],
                            [-7.882, 7.276],
                            [-6.424, 12.657],
                            [7.485, 12.657],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [158.591, 951.912],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 2",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 2,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [1.919, 8.659],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-7.806, -1.872],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-4.684, 2.497],
                            [-0.628, 0.354],
                            [0, 0],
                            [4.527, 2.029],
                            [0, 0],
                            [-3.121, -1.093],
                            [-0.285, -0.123],
                            [-3.12, 1.093],
                            [0, 0],
                            [0, 0],
                            [4.427, -1.985],
                            [0, 0],
                            [-3.323, -1.841],
                            [-0.598, -0.318],
                            [-0.419, -0.163],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [-1.872, 7.96],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.851, -0.453],
                            [2.833, -1.598],
                            [0, 0],
                            [-4.527, -2.03],
                            [0, 0],
                            [3.035, 1.063],
                            [0, 0],
                            [3.121, -1.093],
                            [0, 0],
                            [-0.08, 0.034],
                            [-4.526, 2.029],
                            [0, 0],
                            [0.484, 0.269],
                            [0.461, 0.247],
                            [3.831, 1.505],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [7.803, -1.874],
                          ],
                          v: [
                            [27.238, -11.549],
                            [20.681, -17.169],
                            [17.404, -15.764],
                            [7.883, -11.705],
                            [-6.937, -12.012],
                            [-17.404, -15.918],
                            [-17.406, -15.921],
                            [-17.761, -16.072],
                            [-20.682, -17.325],
                            [-27.238, -11.705],
                            [-17.248, -1.56],
                            [-18.81, 8.743],
                            [-18.713, 8.763],
                            [-12.253, 10.149],
                            [-18.636, 10.858],
                            [-15.722, 15.022],
                            [-13.262, 14.244],
                            [-11.16, 13.581],
                            [-12.33, 14.635],
                            [-14.282, 16.391],
                            [-7.102, 15.923],
                            [-4.891, 14.714],
                            [-1.327, 12.489],
                            [-6.79, 5.778],
                            [-13.189, 2.968],
                            [-8.507, 2.187],
                            [-0.13, 5.625],
                            [8.507, 2.344],
                            [13.19, 3.123],
                            [13.186, 3.124],
                            [6.79, 5.933],
                            [1.327, 12.645],
                            [5.482, 15.199],
                            [7.102, 16.079],
                            [8.422, 16.691],
                            [14.282, 16.547],
                            [13.256, 15.622],
                            [11.161, 13.738],
                            [15.098, 14.545],
                            [16.398, 14.813],
                            [17.989, 11.941],
                            [18.55, 10.928],
                            [12.254, 10.304],
                            [18.81, 8.898],
                            [17.248, -1.402],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [158.591, 974.686],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 3",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 3,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 10,
              ty: 4,
              nm: "LowerThirdBox",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1794.043, 969.858, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [843.231, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 28,
                      s: [0, 100, 100],
                    },
                    {
                      t: 42,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [843.231, 63.49],
                            [-843.231, 63.49],
                            [-843.231, -63.49],
                            [843.231, -63.49],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "gf",
                      o: {
                        a: 0,
                        k: 100,
                        ix: 10,
                      },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [
                            0, 0.086, 0.047, 0.243, 0.5, 0.1, 0.045, 0.259, 1,
                            0.114, 0.043, 0.275,
                          ],
                          ix: 9,
                        },
                      },
                      s: {
                        a: 0,
                        k: [-91.813, 365.534],
                        ix: 5,
                      },
                      e: {
                        a: 0,
                        k: [62.363, -251.17],
                        ix: 6,
                      },
                      t: 1,
                      nm: "Gradient Fill 1",
                      mn: "ADBE Vector Graphic - G-Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
          ],
        },
        {
          id: "comp_23",
          nm: "News Article List News 2",
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 5,
              nm: ".newsTitle4",
              cl: "newsTitle4",
              parent: 2,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [185.44, 28.997, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [182.247, -12.151, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 34.6677398681641,
                        f: "Poppins-SemiBold",
                        t: data[3].headline_snippet,
                        ca: 1,
                        j: 0,
                        tr: 0,
                        lh: 41.6012802124023,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 85,
              op: 1358,
              st: 51,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 5,
              nm: "4",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 85,
                      s: [1367.794, 1845.927, 0],
                      to: [0, -178.333, 0],
                      ti: [0, 178.333, 0],
                    },
                    {
                      t: 102,
                      s: [1367.794, 775.927, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [29.661, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 86.348747253418,
                        f: "Poppins-Bold",
                        t: "4",
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 103.618499755859,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 85,
              op: 1358,
              st: 51,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 5,
              nm: ".newsTitle3",
              cl: "newsTitle3",
              parent: 4,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [185.44, 28.996, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [182.247, -12.151, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 34.6677398681641,
                        f: "Poppins-SemiBold",
                        t: data[2].headline_snippet,
                        ca: 1,
                        j: 0,
                        tr: 0,
                        lh: 41.6012802124023,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 68,
              op: 1341,
              st: 34,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 4,
              ty: 5,
              nm: "3",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 68,
                      s: [1363.692, 1651.454, 0],
                      to: [0, -178.333, 0],
                      ti: [0, 178.333, 0],
                    },
                    {
                      t: 85,
                      s: [1363.692, 581.454, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [25.559, 0.518, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 86.348747253418,
                        f: "Poppins-Bold",
                        t: "3",
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 103.618499755859,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 68,
              op: 1341,
              st: 34,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 5,
              ty: 5,
              nm: ".currentTitleList",
              cl: "currentTitleList",
              parent: 6,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [185.44, 28.997, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [182.247, -12.151, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 34.6677398681641,
                        f: "Poppins-SemiBold",
                        t: data[1].headline_snippet,
                        ca: 1,
                        j: 0,
                        tr: 0,
                        lh: 41.6012802124023,
                        ls: 0,
                        fc: [0.475, 0.694, 0.914],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 51,
              op: 1324,
              st: 17,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 6,
              ty: 5,
              nm: "2",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 51,
                      s: [1363.088, 1455.082, 0],
                      to: [0, -178.333, 0],
                      ti: [0, 178.333, 0],
                    },
                    {
                      t: 68,
                      s: [1363.088, 385.082, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [24.955, -0.863, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 0.1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0.9, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 116,
                      s: [100, 100, 100],
                    },
                    {
                      i: {
                        x: [0.1, 0.1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0.9, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 126,
                      s: [136, 136, 100],
                    },
                    {
                      i: {
                        x: [0.1, 0.1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0.9, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 155,
                      s: [136, 136, 100],
                    },
                    {
                      t: 165,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 86.348747253418,
                        f: "Poppins-Bold",
                        t: "2",
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 103.618499755859,
                        ls: 0,
                        fc: [0.475, 0.694, 0.914],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 51,
              op: 1324,
              st: 17,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 7,
              ty: 5,
              nm: ".newsTitle1",
              cl: "newsTitle1",
              parent: 8,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [185.44, 28.997, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [182.247, -12.151, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 34.6677398681641,
                        f: "Poppins-SemiBold",
                        t: data[0].headline_snippet,
                        ca: 1,
                        j: 0,
                        tr: 0,
                        lh: 41.6012802124023,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 34,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 8,
              ty: 5,
              nm: "1",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 34,
                      s: [1352.424, 1260.955, 0],
                      to: [0, -178.333, 0],
                      ti: [0, 178.333, 0],
                    },
                    {
                      t: 51,
                      s: [1352.424, 190.955, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [14.291, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 86.348747253418,
                        f: "Poppins-Bold",
                        t: "1",
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 103.618499755859,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 34,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 9,
              ty: 4,
              nm: "Layer 5",
              td: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 0,
                      s: [144.128, 1213.321, 0],
                      to: [0, -49.667, 0],
                      ti: [0, 49.667, 0],
                    },
                    {
                      t: 15,
                      s: [144.128, 915.321, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [144.128, 936.157, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [116.407, 86.813],
                            [-116.407, 86.813],
                            [-116.407, -86.813],
                            [116.407, -86.813],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.223529413342, 0.407843142748, 0.678431391716, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [144.128, 936.157],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 10,
              ty: 5,
              nm: "2",
              tt: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 12,
                      s: [-56.254, 912.614, 0],
                      to: [34, 0, 0],
                      ti: [-34, 0, 0],
                    },
                    {
                      t: 31,
                      s: [147.746, 912.614, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [41.198, -53.886, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 142.555236816406,
                        f: "Poppins-Bold",
                        t: "2",
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 171.066284179688,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 11,
              ty: 4,
              nm: "Layer 4",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 8,
                      s: [144.128, 1213.321, 0],
                      to: [0, -49.667, 0],
                      ti: [0, 49.667, 0],
                    },
                    {
                      t: 30,
                      s: [144.128, 915.321, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [144.128, 936.157, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [116.407, 86.813],
                            [-116.407, 86.813],
                            [-116.407, -86.813],
                            [116.407, -86.813],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.223529413342, 0.407843142748, 0.678431391716, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [144.128, 936.157],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 12,
              ty: 5,
              nm: ".currentTitle",
              cl: "currentTitle",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 14,
                      s: [335.151, 1223.704, 0],
                      to: [0, -38, 0],
                      ti: [0, 38, 0],
                    },
                    {
                      t: 36,
                      s: [335.151, 995.704, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 57.2337989807129,
                        f: "Poppins-SemiBold",
                        t: data[1].headline_snippet,
                        ca: 1,
                        j: 0,
                        tr: 0,
                        lh: 68.6805572509766,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 13,
              ty: 0,
              nm: "Logo",
              refId: "comp_15",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 19,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              w: 1920,
              h: 1080,
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 14,
              ty: 4,
              nm: "Layer 6",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960.182, 540.221, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-960.221, 362.987],
                            [-960.221, 540],
                            [960.221, 540],
                            [960.221, -540],
                            [163.669, -540],
                            [163.669, 362.987],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0, 0, 0, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 15,
              ty: 2,
              nm: ".ImageList2",
              cl: "ImageList2",
              refId: "ListImage2",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [176, 108, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [716.466, 251.749, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.833, 0.833, 0.833],
                        y: [0.833, 0.833, 0.833],
                      },
                      o: {
                        x: [0.167, 0.167, 0.167],
                        y: [0.167, 0.167, 0.167],
                      },
                      t: 0,
                      s: [48.463, 48.463, 100],
                    },
                    {
                      t: 301,
                      s: [62.193, 62.193, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
          ],
        },
        {
          id: "comp_24",
          nm: "Mexem News Article 1",
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 0,
              nm: ".NewsDescription1",
              cl: "NewsDescription1",
              refId: "comp_25",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              w: 1920,
              h: 1080,
              ip: 118,
              op: 1425,
              st: 118,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 0,
              nm: ".NewsLower1",
              cl: "NewsLower1",
              refId: "comp_26",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              w: 1920,
              h: 1080,
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 4,
              nm: "BGMask",
              td: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 4,
              ty: 2,
              nm: ".ImageNews1",
              cl: "ImageNews1",
              tt: 2,
              refId: "ArticleImage1",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1452, 292, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [2218.651, 532.377, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.833, 0.833, 0.833],
                        y: [0.833, 0.833, 0.833],
                      },
                      o: {
                        x: [0.167, 0.167, 0.167],
                        y: [0.167, 0.167, 0.167],
                      },
                      t: 0,
                      s: [66.792, 66.792, 100],
                    },
                    {
                      t: 305,
                      s: [82.338, 82.338, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
          ],
        },
        {
          id: "comp_25",
          nm: "DescriptionBox News 1",
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 4,
              nm: "DescriptionBG 2",
              td: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 90,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 13,
                      s: [1436.647, 289.305, 0],
                      to: [-54.125, -18.792, 0],
                      ti: [54.125, 18.792, 0],
                    },
                    {
                      t: 25,
                      s: [1111.897, 176.555, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [-344.595, -146.509, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 0.1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0.9, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 13,
                      s: [6.702, 15.619, 100],
                    },
                    {
                      t: 25,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [344.595, 146.509],
                            [-344.595, 146.509],
                            [-344.595, -146.509],
                            [344.595, -146.509],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "gf",
                      o: {
                        a: 0,
                        k: 100,
                        ix: 10,
                      },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [
                            0, 0.086, 0.047, 0.243, 0.5, 0.1, 0.045, 0.259, 1,
                            0.114, 0.043, 0.275,
                          ],
                          ix: 9,
                        },
                      },
                      s: {
                        a: 0,
                        k: [-345.492, -0.672],
                        ix: 5,
                      },
                      e: {
                        a: 0,
                        k: [343.699, -0.672],
                        ix: 6,
                      },
                      t: 1,
                      nm: "Gradient Fill 1",
                      mn: "ADBE Vector Graphic - G-Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 5,
              nm: ".NewsDescription1",
              cl: "NewsDescription1",
              tt: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1451.583, 310.336, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [-5.493, -26.604, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        sz: [596.73046875, 198.996856689453],
                        ps: [-298.365234375, -111.771141052246],
                        s: 29.9096698760986,
                        f: "Poppins-SemiBold",
                        t: data[0].description,
                        ca: 1,
                        j: 0,
                        tr: 0,
                        lh: 35.8916091918945,
                        ls: 0,
                        fc: [0.949, 0.949, 0.949],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 4,
              nm: "DescriptionSquareHalf2",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 13,
                      s: [1459.354, 311.778, 0],
                      to: [53, 22.75, 0],
                      ti: [-53, -22.75, 0],
                    },
                    {
                      t: 25,
                      s: [1777.354, 448.278, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 0.1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0.9, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 0,
                      s: [0, 0, 100],
                    },
                    {
                      t: 6,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [23.408, -23.408],
                            [23.408, 23.408],
                            [-23.408, 23.408],
                          ],
                          c: false,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.956862747669, 0.956862747669, 0.956862747669, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 4,
              ty: 4,
              nm: "DescriptionSquareHalf1",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 13,
                      s: [1459.867, 312.086, 0],
                      to: [-54.094, -18.687, 0],
                      ti: [54.094, 18.687, 0],
                    },
                    {
                      t: 25,
                      s: [1135.304, 199.962, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 0.1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0.9, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 0,
                      s: [0, 0, 100],
                    },
                    {
                      t: 6,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-23.408, 23.408],
                            [-23.408, -23.408],
                            [23.408, -23.408],
                          ],
                          c: false,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.956862747669, 0.956862747669, 0.956862747669, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 5,
              ty: 4,
              nm: "DescriptionBG",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 90,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 13,
                      s: [1436.647, 289.305, 0],
                      to: [-54.125, -18.792, 0],
                      ti: [54.125, 18.792, 0],
                    },
                    {
                      t: 25,
                      s: [1111.897, 176.555, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [-344.595, -146.509, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 0.1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0.9, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 13,
                      s: [6.702, 15.619, 100],
                    },
                    {
                      t: 25,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [344.595, 146.509],
                            [-344.595, 146.509],
                            [-344.595, -146.509],
                            [344.595, -146.509],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "gf",
                      o: {
                        a: 0,
                        k: 100,
                        ix: 10,
                      },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [
                            0, 0.086, 0.047, 0.243, 0.5, 0.1, 0.045, 0.259, 1,
                            0.114, 0.043, 0.275,
                          ],
                          ix: 9,
                        },
                      },
                      s: {
                        a: 0,
                        k: [-345.492, -0.672],
                        ix: 5,
                      },
                      e: {
                        a: 0,
                        k: [343.699, -0.672],
                        ix: 6,
                      },
                      t: 1,
                      nm: "Gradient Fill 1",
                      mn: "ADBE Vector Graphic - G-Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
          ],
        },
        {
          id: "comp_26",
          nm: "Lower Third News 1",
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 4,
              nm: "HeadlineBox 2",
              td: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [83.282, 885.028, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [83.282, 880.267, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 45,
                      s: [0, 100, 100],
                    },
                    {
                      t: 56,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [165.902, 34.873],
                            [-165.902, 34.873],
                            [-165.902, -34.873],
                            [165.902, -34.873],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.227450981736, 0.407843142748, 0.68235296011, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [249.184, 880.267],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 5,
              nm: "HeadlineText",
              tt: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 63,
                      s: [-214.275, 886.46, 0],
                      to: [57.333, 0, 0],
                      ti: [-57.333, 0, 0],
                    },
                    {
                      t: 74,
                      s: [129.725, 886.46, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [3.233, -16.42, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 46.8478507995605,
                        f: "Poppins-SemiBold",
                        t: "HEADLINES",
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 56.2174301147461,
                        ls: 0,
                        fc: [0.949, 0.949, 0.949],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 4,
              nm: "HeadlineBox",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [83.282, 885.028, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [83.282, 880.267, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 52,
                      s: [0, 100, 100],
                    },
                    {
                      t: 63,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [165.902, 34.873],
                            [-165.902, 34.873],
                            [-165.902, -34.873],
                            [165.902, -34.873],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.227450981736, 0.407843142748, 0.68235296011, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [249.184, 880.267],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 4,
              ty: 5,
              nm: ".NewsHealineNumber1",
              cl: "NewsHealineNumber1",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 0,
                      s: [126.696, 1284.042, 0],
                      to: [0, -48, 0],
                      ti: [0, 48, 0],
                    },
                    {
                      i: {
                        x: 0.1,
                        y: 0.1,
                      },
                      o: {
                        x: 0.9,
                        y: 0.9,
                      },
                      t: 15,
                      s: [126.696, 996.042, 0],
                      to: [0, 0, 0],
                      ti: [0, 0, 0],
                    },
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 30,
                      s: [126.696, 996.042, 0],
                      to: [256.333, 0, 0],
                      ti: [-256.333, 0, 0],
                    },
                    {
                      t: 44,
                      s: [1664.696, 996.042, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 200.433181762695,
                        f: "Poppins-Bold",
                        t: "1",
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 240.519821166992,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 5,
              ty: 4,
              nm: "LowerThirdLine",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [950.524, 1033.348, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-843.519, 0],
                            [843.519, 0],
                          ],
                          c: false,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "st",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 3,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 4,
                      },
                      w: {
                        a: 0,
                        k: 19,
                        ix: 5,
                      },
                      lc: 1,
                      lj: 1,
                      ml: 10,
                      bm: 0,
                      nm: "Stroke 1",
                      mn: "ADBE Vector Graphic - Stroke",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "tm",
                  s: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.1],
                          y: [1],
                        },
                        o: {
                          x: [0.9],
                          y: [0],
                        },
                        t: 30,
                        s: [100],
                      },
                      {
                        t: 44,
                        s: [0],
                      },
                    ],
                    ix: 1,
                  },
                  e: {
                    a: 0,
                    k: 100,
                    ix: 2,
                  },
                  o: {
                    a: 0,
                    k: 0,
                    ix: 3,
                  },
                  m: 1,
                  ix: 2,
                  nm: "Trim Paths 1",
                  mn: "ADBE Vector Filter - Trim",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 6,
              ty: 4,
              nm: "LowerThirdBox 3",
              td: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1794.043, 969.858, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [843.231, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 35,
                      s: [0, 100, 100],
                    },
                    {
                      t: 49,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [843.231, 63.49],
                            [-843.231, 63.49],
                            [-843.231, -63.49],
                            [843.231, -63.49],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "gf",
                      o: {
                        a: 0,
                        k: 100,
                        ix: 10,
                      },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [
                            0, 0.086, 0.047, 0.243, 0.5, 0.1, 0.045, 0.259, 1,
                            0.114, 0.043, 0.275,
                          ],
                          ix: 9,
                        },
                      },
                      s: {
                        a: 0,
                        k: [-91.813, 365.534],
                        ix: 5,
                      },
                      e: {
                        a: 0,
                        k: [62.363, -251.17],
                        ix: 6,
                      },
                      t: 1,
                      nm: "Gradient Fill 1",
                      mn: "ADBE Vector Graphic - G-Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 7,
              ty: 5,
              nm: ".NewsHeadline1",
              cl: "NewsHeadline1",
              tt: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.25,
                        y: 1,
                      },
                      o: {
                        x: 0.75,
                        y: 0,
                      },
                      t: 78,
                      s: [274.591, 1171.409, 0],
                      to: [0, -30.333, 0],
                      ti: [0, 30.333, 0],
                    },
                    {
                      t: 104,
                      s: [274.591, 989.409, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 35,
                        f: "Poppins-SemiBold",
                        t: data[0].headline,
                        ca: 1,
                        j: 0,
                        tr: 0,
                        lh: 68.6805572509766,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 8,
              ty: 4,
              nm: "LowerThirdBox 2",
              td: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1794.043, 969.858, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [843.231, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 35,
                      s: [0, 100, 100],
                    },
                    {
                      t: 49,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [843.231, 63.49],
                            [-843.231, 63.49],
                            [-843.231, -63.49],
                            [843.231, -63.49],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "gf",
                      o: {
                        a: 0,
                        k: 100,
                        ix: 10,
                      },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [
                            0, 0.086, 0.047, 0.243, 0.5, 0.1, 0.045, 0.259, 1,
                            0.114, 0.043, 0.275,
                          ],
                          ix: 9,
                        },
                      },
                      s: {
                        a: 0,
                        k: [-91.813, 365.534],
                        ix: 5,
                      },
                      e: {
                        a: 0,
                        k: [62.363, -251.17],
                        ix: 6,
                      },
                      t: 1,
                      nm: "Gradient Fill 1",
                      mn: "ADBE Vector Graphic - G-Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 9,
              ty: 4,
              nm: "Logo",
              tt: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.25,
                        y: 1,
                      },
                      o: {
                        x: 0.75,
                        y: 0,
                      },
                      t: 52,
                      s: [158.591, 1097.409, 0],
                      to: [0, -21, 0],
                      ti: [0, 21, 0],
                    },
                    {
                      t: 78,
                      s: [158.591, 971.409, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [158.591, 971.409, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [1.249, 3.434],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-1.249, 3.434],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [0.051, -6.672],
                            [0, -6.698],
                            [-0.017, -6.688],
                            [-0.019, -6.687],
                            [-3.171, -4.996],
                            [-8.377, -2.198],
                            [-6.503, 3.89],
                            [-5.411, 1.547],
                            [0, 6.698],
                            [2.195, 4.529],
                            [5.412, 1.495],
                            [6.503, 3.837],
                            [8.377, -2.251],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [158.461, 996.865],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.122, 0.08],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [1.248, -2.497],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-1.239, -2.477],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [8.819, 7.324],
                            [8.821, 7.322],
                            [19.901, 1.391],
                            [19.744, -4.853],
                            [10.537, -12.499],
                            [10.535, -12.499],
                            [10.535, -12.5],
                            [-0.122, -6.998],
                            [-10.535, -12.657],
                            [-19.746, -5.009],
                            [-19.901, 1.235],
                            [-7.882, 7.276],
                            [-6.424, 12.657],
                            [7.485, 12.657],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [158.591, 951.912],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 2",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 2,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [1.919, 8.659],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-7.806, -1.872],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-4.684, 2.497],
                            [-0.628, 0.354],
                            [0, 0],
                            [4.527, 2.029],
                            [0, 0],
                            [-3.121, -1.093],
                            [-0.285, -0.123],
                            [-3.12, 1.093],
                            [0, 0],
                            [0, 0],
                            [4.427, -1.985],
                            [0, 0],
                            [-3.323, -1.841],
                            [-0.598, -0.318],
                            [-0.419, -0.163],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [-1.872, 7.96],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.851, -0.453],
                            [2.833, -1.598],
                            [0, 0],
                            [-4.527, -2.03],
                            [0, 0],
                            [3.035, 1.063],
                            [0, 0],
                            [3.121, -1.093],
                            [0, 0],
                            [-0.08, 0.034],
                            [-4.526, 2.029],
                            [0, 0],
                            [0.484, 0.269],
                            [0.461, 0.247],
                            [3.831, 1.505],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [7.803, -1.874],
                          ],
                          v: [
                            [27.238, -11.549],
                            [20.681, -17.169],
                            [17.404, -15.764],
                            [7.883, -11.705],
                            [-6.937, -12.012],
                            [-17.404, -15.918],
                            [-17.406, -15.921],
                            [-17.761, -16.072],
                            [-20.682, -17.325],
                            [-27.238, -11.705],
                            [-17.248, -1.56],
                            [-18.81, 8.743],
                            [-18.713, 8.763],
                            [-12.253, 10.149],
                            [-18.636, 10.858],
                            [-15.722, 15.022],
                            [-13.262, 14.244],
                            [-11.16, 13.581],
                            [-12.33, 14.635],
                            [-14.282, 16.391],
                            [-7.102, 15.923],
                            [-4.891, 14.714],
                            [-1.327, 12.489],
                            [-6.79, 5.778],
                            [-13.189, 2.968],
                            [-8.507, 2.187],
                            [-0.13, 5.625],
                            [8.507, 2.344],
                            [13.19, 3.123],
                            [13.186, 3.124],
                            [6.79, 5.933],
                            [1.327, 12.645],
                            [5.482, 15.199],
                            [7.102, 16.079],
                            [8.422, 16.691],
                            [14.282, 16.547],
                            [13.256, 15.622],
                            [11.161, 13.738],
                            [15.098, 14.545],
                            [16.398, 14.813],
                            [17.989, 11.941],
                            [18.55, 10.928],
                            [12.254, 10.304],
                            [18.81, 8.898],
                            [17.248, -1.402],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [1, 1, 1, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [158.591, 974.686],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 3",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 3,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 10,
              ty: 4,
              nm: "LowerThirdBox",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [1794.043, 969.858, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [843.231, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 28,
                      s: [0, 100, 100],
                    },
                    {
                      t: 42,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [843.231, 63.49],
                            [-843.231, 63.49],
                            [-843.231, -63.49],
                            [843.231, -63.49],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "gf",
                      o: {
                        a: 0,
                        k: 100,
                        ix: 10,
                      },
                      r: 1,
                      bm: 0,
                      g: {
                        p: 3,
                        k: {
                          a: 0,
                          k: [
                            0, 0.086, 0.047, 0.243, 0.5, 0.1, 0.045, 0.259, 1,
                            0.114, 0.043, 0.275,
                          ],
                          ix: 9,
                        },
                      },
                      s: {
                        a: 0,
                        k: [-91.813, 365.534],
                        ix: 5,
                      },
                      e: {
                        a: 0,
                        k: [62.363, -251.17],
                        ix: 6,
                      },
                      t: 1,
                      nm: "Gradient Fill 1",
                      mn: "ADBE Vector Graphic - G-Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
          ],
        },
        {
          id: "comp_27",
          nm: "News Article List News 1",
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 5,
              nm: ".newsTitle4",
              cl: "newsTitle4",
              parent: 2,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [185.44, 28.997, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [182.247, -12.151, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 34.6677398681641,
                        f: "Poppins-SemiBold",
                        t: data[3].headline_snippet,
                        ca: 1,
                        j: 0,
                        tr: 0,
                        lh: 41.6012802124023,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 85,
              op: 1358,
              st: 51,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 5,
              nm: "4",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 85,
                      s: [1367.794, 1845.927, 0],
                      to: [0, -178.333, 0],
                      ti: [0, 178.333, 0],
                    },
                    {
                      t: 102,
                      s: [1367.794, 775.927, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [29.661, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 86.348747253418,
                        f: "Poppins-Bold",
                        t: "4",
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 103.618499755859,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 85,
              op: 1358,
              st: 51,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 5,
              nm: ".newsTitle3",
              cl: "newsTitle3",
              parent: 4,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [185.44, 28.996, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [182.247, -12.151, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 34.6677398681641,
                        f: "Poppins-SemiBold",
                        t: data[2].headline_snippet,
                        ca: 1,
                        j: 0,
                        tr: 0,
                        lh: 41.6012802124023,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 68,
              op: 1341,
              st: 34,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 4,
              ty: 5,
              nm: "3",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 68,
                      s: [1363.692, 1651.454, 0],
                      to: [0, -178.333, 0],
                      ti: [0, 178.333, 0],
                    },
                    {
                      t: 85,
                      s: [1363.692, 581.454, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [25.559, 0.518, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 86.348747253418,
                        f: "Poppins-Bold",
                        t: "3",
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 103.618499755859,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 68,
              op: 1341,
              st: 34,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 5,
              ty: 5,
              nm: ".newsTitle2",
              cl: "newsTitle2",
              parent: 6,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [185.44, 28.997, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [182.247, -12.151, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 34.6677398681641,
                        f: "Poppins-SemiBold",
                        t: data[1].headline_snippet,
                        ca: 1,
                        j: 0,
                        tr: 0,
                        lh: 41.6012802124023,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 51,
              op: 1324,
              st: 17,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 6,
              ty: 5,
              nm: "2",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 51,
                      s: [1363.088, 1455.082, 0],
                      to: [0, -178.333, 0],
                      ti: [0, 178.333, 0],
                    },
                    {
                      t: 68,
                      s: [1363.088, 385.082, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [24.955, -0.863, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 86.348747253418,
                        f: "Poppins-Bold",
                        t: "2",
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 103.618499755859,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 51,
              op: 1324,
              st: 17,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 7,
              ty: 5,
              nm: ".currentTitleList",
              cl: "currentTitleList",
              parent: 8,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [185.44, 28.997, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [182.247, -12.151, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 34.6677398681641,
                        f: "Poppins-SemiBold",
                        t: data[0].headline_snippet,
                        ca: 1,
                        j: 0,
                        tr: 0,
                        lh: 41.6012802124023,
                        ls: 0,
                        fc: [0.475, 0.694, 0.914],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 34,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 8,
              ty: 5,
              nm: "1",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 34,
                      s: [1352.424, 1260.955, 0],
                      to: [0, -178.333, 0],
                      ti: [0, 178.333, 0],
                    },
                    {
                      t: 51,
                      s: [1352.424, 190.955, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [14.291, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.1, 0.1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0.9, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 116,
                      s: [100, 100, 100],
                    },
                    {
                      i: {
                        x: [0.1, 0.1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0.9, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 126,
                      s: [136, 136, 100],
                    },
                    {
                      i: {
                        x: [0.1, 0.1, 0.833],
                        y: [1, 1, 1],
                      },
                      o: {
                        x: [0.9, 0.9, 0.167],
                        y: [0, 0, 0],
                      },
                      t: 155,
                      s: [136, 136, 100],
                    },
                    {
                      t: 165,
                      s: [100, 100, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 86.348747253418,
                        f: "Poppins-Bold",
                        t: "1",
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 103.618499755859,
                        ls: 0,
                        fc: [0.473, 0.693, 0.914],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 34,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 9,
              ty: 4,
              nm: "Layer 5",
              td: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 0,
                      s: [144.128, 1213.321, 0],
                      to: [0, -49.667, 0],
                      ti: [0, 49.667, 0],
                    },
                    {
                      t: 15,
                      s: [144.128, 915.321, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [144.128, 936.157, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [116.407, 86.813],
                            [-116.407, 86.813],
                            [-116.407, -86.813],
                            [116.407, -86.813],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.223529413342, 0.407843142748, 0.678431391716, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [144.128, 936.157],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 10,
              ty: 5,
              nm: "2",
              tt: 1,
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 12,
                      s: [-56.254, 912.614, 0],
                      to: [35.705, 0, 0],
                      ti: [-35.705, 0, 0],
                    },
                    {
                      t: 31,
                      s: [157.973, 912.614, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [41.198, -53.886, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 142.555236816406,
                        f: "Poppins-Bold",
                        t: "1",
                        ca: 0,
                        j: 0,
                        tr: 0,
                        lh: 171.066284179688,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 11,
              ty: 4,
              nm: "Layer 4",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 8,
                      s: [144.128, 1213.321, 0],
                      to: [0, -49.667, 0],
                      ti: [0, 49.667, 0],
                    },
                    {
                      t: 30,
                      s: [144.128, 915.321, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [144.128, 936.157, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [116.407, 86.813],
                            [-116.407, 86.813],
                            [-116.407, -86.813],
                            [116.407, -86.813],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0.223529413342, 0.407843142748, 0.678431391716, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [144.128, 936.157],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 12,
              ty: 5,
              nm: ".currentTitle",
              cl: "currentTitle",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: 0.1,
                        y: 1,
                      },
                      o: {
                        x: 0.9,
                        y: 0,
                      },
                      t: 14,
                      s: [335.151, 1223.704, 0],
                      to: [0, -38, 0],
                      ti: [0, 38, 0],
                    },
                    {
                      t: 36,
                      s: [335.151, 995.704, 0],
                    },
                  ],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              t: {
                d: {
                  k: [
                    {
                      s: {
                        s: 57.2337989807129,
                        f: "Poppins-SemiBold",
                        t: data[0].headline_snippet,
                        ca: 1,
                        j: 0,
                        tr: 0,
                        lh: 68.6805572509766,
                        ls: 0,
                        fc: [1, 1, 1],
                      },
                      t: 0,
                    },
                  ],
                },
                p: {},
                m: {
                  g: 1,
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                  },
                },
                a: [],
              },
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 13,
              ty: 0,
              nm: "Logo",
              refId: "comp_15",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 19,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [960, 540, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              w: 1920,
              h: 1080,
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 14,
              ty: 4,
              nm: "Layer 6",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [960.182, 540.221, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [0, 0, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 0,
                  k: [100, 100, 100],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ind: 0,
                      ty: "sh",
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-960.221, 362.987],
                            [-960.221, 540],
                            [960.221, 540],
                            [960.221, -540],
                            [163.669, -540],
                            [163.669, 362.987],
                          ],
                          c: true,
                        },
                        ix: 2,
                      },
                      nm: "Path 1",
                      mn: "ADBE Vector Shape - Group",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: {
                        a: 0,
                        k: [0, 0, 0, 1],
                        ix: 4,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 5,
                      },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: {
                        a: 0,
                        k: [0, 0],
                        ix: 2,
                      },
                      a: {
                        a: 0,
                        k: [0, 0],
                        ix: 1,
                      },
                      s: {
                        a: 0,
                        k: [100, 100],
                        ix: 3,
                      },
                      r: {
                        a: 0,
                        k: 0,
                        ix: 6,
                      },
                      o: {
                        a: 0,
                        k: 100,
                        ix: 7,
                      },
                      sk: {
                        a: 0,
                        k: 0,
                        ix: 4,
                      },
                      sa: {
                        a: 0,
                        k: 0,
                        ix: 5,
                      },
                      nm: "Transform",
                    },
                  ],
                  nm: "Group 1",
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 15,
              ty: 2,
              nm: ".ImageList1",
              cl: "ImageList1",
              refId: "ListImage1",
              sr: 1,
              ks: {
                o: {
                  a: 0,
                  k: 100,
                  ix: 11,
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10,
                },
                p: {
                  a: 0,
                  k: [176, 108, 0],
                  ix: 2,
                  l: 2,
                },
                a: {
                  a: 0,
                  k: [716.466, 251.749, 0],
                  ix: 1,
                  l: 2,
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.833, 0.833, 0.833],
                        y: [0.833, 0.833, 0.833],
                      },
                      o: {
                        x: [0.167, 0.167, 0.167],
                        y: [0.167, 0.167, 0.167],
                      },
                      t: 0,
                      s: [48.463, 48.463, 100],
                    },
                    {
                      t: 301,
                      s: [62.193, 62.193, 100],
                    },
                  ],
                  ix: 6,
                  l: 2,
                },
              },
              ao: 0,
              ip: 0,
              op: 1307,
              st: 0,
              bm: 0,
            },
          ],
        },
      ],
      fonts: {
        list: [
          {
            origin: 1,
            fPath: "",
            fClass: "",
            fFamily: "Poppins",
            fWeight: "",
            fStyle: "SemiBold",
            fName: "Poppins-SemiBold",
            ascent: 73.9990234375,
          },
          {
            origin: 1,
            fPath: "",
            fClass: "",
            fFamily: "Poppins",
            fWeight: "",
            fStyle: "Bold",
            fName: "Poppins-Bold",
            ascent: 73.9990234375,
          },
          {
            origin: 1,
            fPath: "",
            fClass: "",
            fFamily: "Poppins",
            fWeight: "",
            fStyle: "Medium",
            fName: "Poppins-Medium",
            ascent: 73.9990234375,
          },
        ],
      },
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 0,
          nm: "Intro & Transitions",
          refId: "comp_0",
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11,
            },
            r: {
              a: 0,
              k: 0,
              ix: 10,
            },
            p: {
              a: 0,
              k: [960, 540, 0],
              ix: 2,
              l: 2,
            },
            a: {
              a: 0,
              k: [960, 540, 0],
              ix: 1,
              l: 2,
            },
            s: {
              a: 0,
              k: [100, 100, 100],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          w: 1920,
          h: 1080,
          ip: 0,
          op: 2168,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 0,
          nm: "Mexem News Article 4",
          refId: "comp_11",
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11,
            },
            r: {
              a: 0,
              k: 0,
              ix: 10,
            },
            p: {
              a: 0,
              k: [960, 540, 0],
              ix: 2,
              l: 2,
            },
            a: {
              a: 0,
              k: [960, 540, 0],
              ix: 1,
              l: 2,
            },
            s: {
              a: 0,
              k: [100, 100, 100],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          w: 1920,
          h: 1080,
          ip: 2150,
          op: 2454,
          st: 2150,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 0,
          nm: "News Article List News 4",
          refId: "comp_14",
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11,
            },
            r: {
              a: 0,
              k: 0,
              ix: 10,
            },
            p: {
              a: 0,
              k: [960, 540, 0],
              ix: 2,
              l: 2,
            },
            a: {
              a: 0,
              k: [960, 540, 0],
              ix: 1,
              l: 2,
            },
            s: {
              a: 0,
              k: [100, 100, 100],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          w: 1920,
          h: 1080,
          ip: 1903,
          op: 2150,
          st: 1903,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 0,
          nm: "Mexem News Article 3",
          refId: "comp_16",
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11,
            },
            r: {
              a: 0,
              k: 0,
              ix: 10,
            },
            p: {
              a: 0,
              k: [960, 540, 0],
              ix: 2,
              l: 2,
            },
            a: {
              a: 0,
              k: [960, 540, 0],
              ix: 1,
              l: 2,
            },
            s: {
              a: 0,
              k: [100, 100, 100],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          w: 1920,
          h: 1080,
          ip: 1599,
          op: 1903,
          st: 1599,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 0,
          nm: "News Article List News 3",
          refId: "comp_19",
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11,
            },
            r: {
              a: 0,
              k: 0,
              ix: 10,
            },
            p: {
              a: 0,
              k: [960, 540, 0],
              ix: 2,
              l: 2,
            },
            a: {
              a: 0,
              k: [960, 540, 0],
              ix: 1,
              l: 2,
            },
            s: {
              a: 0,
              k: [100, 100, 100],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          w: 1920,
          h: 1080,
          ip: 1352,
          op: 1599,
          st: 1352,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 0,
          nm: "Mexem News Article 2",
          refId: "comp_20",
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11,
            },
            r: {
              a: 0,
              k: 0,
              ix: 10,
            },
            p: {
              a: 0,
              k: [960, 540, 0],
              ix: 2,
              l: 2,
            },
            a: {
              a: 0,
              k: [960, 540, 0],
              ix: 1,
              l: 2,
            },
            s: {
              a: 0,
              k: [100, 100, 100],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          w: 1920,
          h: 1080,
          ip: 1048,
          op: 1352,
          st: 1048,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 0,
          nm: "News Article List News 2",
          refId: "comp_23",
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11,
            },
            r: {
              a: 0,
              k: 0,
              ix: 10,
            },
            p: {
              a: 0,
              k: [960, 540, 0],
              ix: 2,
              l: 2,
            },
            a: {
              a: 0,
              k: [960, 540, 0],
              ix: 1,
              l: 2,
            },
            s: {
              a: 0,
              k: [100, 100, 100],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          w: 1920,
          h: 1080,
          ip: 801,
          op: 1048,
          st: 801,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 0,
          nm: "Mexem News Article 1",
          refId: "comp_24",
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11,
            },
            r: {
              a: 0,
              k: 0,
              ix: 10,
            },
            p: {
              a: 0,
              k: [960, 540, 0],
              ix: 2,
              l: 2,
            },
            a: {
              a: 0,
              k: [960, 540, 0],
              ix: 1,
              l: 2,
            },
            s: {
              a: 0,
              k: [100, 100, 100],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          w: 1920,
          h: 1080,
          ip: 497,
          op: 801,
          st: 497,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 0,
          nm: "News Article List News 1",
          refId: "comp_27",
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11,
            },
            r: {
              a: 0,
              k: 0,
              ix: 10,
            },
            p: {
              a: 0,
              k: [960, 540, 0],
              ix: 2,
              l: 2,
            },
            a: {
              a: 0,
              k: [960, 540, 0],
              ix: 1,
              l: 2,
            },
            s: {
              a: 0,
              k: [100, 100, 100],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          w: 1920,
          h: 1080,
          ip: 250,
          op: 497,
          st: 250,
          bm: 0,
        },
      ],
      markers: [],
    };

    return (
      <Lottie
        options={{
          animationData: lottieJSON,
          loop: false,
        }}
      />
    );
  } catch (err) {
    return (
      <Lottie
        width={100}
        height={100}
        options={{
          animationData: lottieError,
          loop: true,
        }}
      />
    );
  }
}
