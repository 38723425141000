import axios from "axios";
import { format, subHours } from "date-fns";

export default async function getNewsRating() {
  const dateTimeCurrent = format(new Date(), "yyyy-MM-dd HH:mm:ss");
  const query = {
    query: `
    query EconomicCalendar {
      economicCalendar {
        name
        data
        actual
        economy
        forecast
        previous
        country
        impact
      }
    }
      `,
  };

  var options = {
    method: "post",
    url:
      process.env.REACT_APP_NODE_ENV === "Development"
        ? process.env.REACT_APP_DEV_API_URL
        : process.env.REACT_APP_API_URL,
    data: query,
    headers: {
      Authorization: `API_KEY ${process.env.REACT_APP_API_KEY}`,
    },
  };

  let response = await axios.request(options);
  const data = response.data.data.economicCalendar;
  const filteredArray = data.filter((item) => {
    const today = format(new Date(), "yyyy-MM-dd");
    if (item.data === null) {
      return false;
    }
    if (item.data > dateTimeCurrent) {
      return true;
    } else {
      return false;
    }
  });
  // Shuffle array
  const shuffled = filteredArray.sort(() => 0.5 - Math.random());
  // Get sub-array of first n elements after shuffled
  let selected = shuffled.slice(0, 6);

  console.log(data);

  return selected;
}
