import React, { useState, useEffect } from "react";
import axios from "axios";

function UpdateCalendar() {
  const [msg, setMsg] = useState("");

  const query = {
    query: `
      query EconomicCalendarData {
        economicCalendarData {
          msg  
          }
        }
            `,
  };

  var options = {
    method: "post",
    url:
      process.env.REACT_APP_NODE_ENV === "Development"
        ? process.env.REACT_APP_DEV_API_URL
        : process.env.REACT_APP_API_URL,
    data: query,
    headers: {
      Authorization: `API_KEY ${process.env.REACT_APP_API_KEY}`,
    },
  };

  useEffect(() => {
    getEconomicCalendar();
  }, []);

  const getEconomicCalendar = async () => {
    let response = await axios.request(options);
    setMsg(response.data.data.economicCalendarData.msg);
  };

  return <div>{msg}</div>;
}

export default UpdateCalendar;
