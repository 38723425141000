import axios from "axios";

export default async function getNewsRating({ queryKey }) {
  const [_, symbol, exchange] = queryKey;

  const url =
    process.env.REACT_APP_NODE_ENV === "Development"
      ? process.env.REACT_APP_DEV_API_URL
      : process.env.REACT_APP_API_URL;

  const query1 = {
    query: `
      query{
        symbolNews(symbol: "${symbol}") {
          data
        } 
      }
          `,
  };

  var options1 = {
    method: "post",
    url:
      process.env.REACT_APP_NODE_ENV === "Development"
        ? process.env.REACT_APP_DEV_API_URL
        : process.env.REACT_APP_API_URL,
    data: query1,
    headers: {
      Authorization: `API_KEY ${process.env.REACT_APP_API_KEY}`,
    },
  };

  const query2 = {
    query: `
        query{
          symbolData(symbol: "${symbol}") {
            data
          }
        }
              `,
  };

  var options2 = {
    method: "post",
    url:
      process.env.REACT_APP_NODE_ENV === "Development"
        ? process.env.REACT_APP_DEV_API_URL
        : process.env.REACT_APP_API_URL,
    data: query2,
    headers: {
      Authorization: `API_KEY ${process.env.REACT_APP_API_KEY}`,
    },
  };

  const query3 = {
    query: `
    query Query {
        symbolRating( exchange: "${exchange}", symbol: "${symbol}") {
            based_on
            current_price
            price_target
            rating
            star_rating
            symbol
            rating_subtitle
      
        } 
      }
      
      `,
  };

  var options3 = {
    method: "post",
    url:
      process.env.REACT_APP_NODE_ENV === "Development"
        ? process.env.REACT_APP_DEV_API_URL
        : process.env.REACT_APP_API_URL,
    data: query3,
    headers: {
      Authorization: `API_KEY ${process.env.REACT_APP_API_KEY}`,
    },
  };

  let request1 = axios.request(options1);
  let request2 = axios.request(options2);
  let request3 = axios.request(options3);
  let [newsData, symbolData, ratingData] = await axios.all([
    request1,
    request2,
    request3,
  ]);

  const newsObj = JSON.parse(newsData.data.data.symbolNews.data);
  const newsArray = newsObj.data;

  const symbolObj = symbolData.data.data.symbolData.data;
  const symbolParsed = JSON.parse(symbolObj);

  const filteredNews = newsArray.filter((element) => {
    return element.text.length > 200 && element.title.length < 80;
  });
  const randomNewsItem =
    filteredNews[Math.floor(Math.random() * filteredNews.length)];

  const data = {
    symbol: symbol,
    title: randomNewsItem.title,
    text: randomNewsItem.text,
    image: randomNewsItem.image_url,
    sentiment: randomNewsItem.sentiment,
    source: randomNewsItem.source_name,
    topics: randomNewsItem.topics,
    currentPrice: symbolParsed.close,
    high: symbolParsed.high,
    low: symbolParsed.low,
    stockName: symbolParsed.name,
  };

  const rating = ratingData.data.data.symbolRating;

  return [rating, data];
}
