import React from "react";
import Lottie from "lottie-react-web";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import getTickers from "../../Queries/getTickers";
import loadingLottie from "../../Lottie/loadingLottie.json";
import lottieData from "./tickersLottie";
import lottieError from "../../Lottie/errorLottie.json";

function RQStockTicker() {
  //Get URL Params
  const { symbols } = useParams();
  const { title } = useParams();
  const { interval } = useParams();

  const { isLoading, data, isError } = useQuery(
    ["stockTicker", symbols, interval],
    getTickers
  );

  if (isLoading) {
    return (
      <Lottie
        width={100}
        height={100}
        options={{
          animationData: loadingLottie,
          loop: true,
        }}
      />
    );
  }
  if (isError) {
    return (
      <Lottie
        width={100}
        height={100}
        options={{
          animationData: lottieError,
          loop: true,
        }}
      />
    );
  }
  return lottieData(data, title);
}

export default RQStockTicker;
