import axios from "axios";

export default async function getNewsRating() {
  const query = {
    query: `
    query{
      generalNews {
        data
      }
    }
        `,
  };

  var options = {
    method: "post",
    url:
      process.env.REACT_APP_NODE_ENV === "Development"
        ? process.env.REACT_APP_DEV_API_URL
        : process.env.REACT_APP_API_URL,
    data: query,
    headers: {
      Authorization: `API_KEY ${process.env.REACT_APP_API_KEY}`,
    },
  };

  const response = await axios.request(options);

  const dataArray = response.data.data.generalNews.data;
  const data = JSON.parse(dataArray);

  return data;
}
