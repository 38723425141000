import React, { useEffect, useState } from "react";
//Chart Imports
import { Line } from "react-chartjs-2";

function LineChart({ options, data, parentClassName, childClassName }) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    let timer1 = setTimeout(() => {
      setMounted(true);
    }, 2000);
    let timer2 = setTimeout(() => {
      setMounted(false);
    }, 15400);
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, []);

  if (mounted && data !== undefined) {
    return (
      <div className={parentClassName}>
        <Line
          options={options}
          className={childClassName}
          data={data.chartjs}
        />
      </div>
    );
  } else {
    return <div className={parentClassName}></div>;
  }
}

export default LineChart;
