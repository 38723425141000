import { QueryClientProvider, QueryClient } from "react-query";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import RQStockTicker from "./Components/Stock Ticker/RQStockTicker";
import { ReactQueryDevtools } from "react-query/devtools";
import RQNewsArticle from "./Components/News Article/RQNewsArticle";
import RQNewsArticleRating from "./Components/News Article Rating/RQNewsArticleRating";
import RQEconomicCalendar from "./Components/Economic Calendar/RQEconomicCalendar";
import RQChartMultible from "./Components/Chart Multible/RQChartMultible";
import RQAnalystRating from "./Components/Analyst Rating/RQAnalystRating";
import RQChartSingle from "./Components/Chart Single/RQChartSingle";
import RQDailyNewsArticles from "./Components/Daily News Article/RQDailyNewsArticles";

import CSVData from "./Components/CSVData/CSVData";
import UpdateCalendar from "./Components/Update Calendar/UpdateCalendar";
import RQRandomStockTicker from "./Components/Stock Ticker AutoGen/RQRandomStockTicker";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <div className="App">
          <Routes>
            <Route
              path="/tickers/:title/:symbols/:interval"
              element={<RQStockTicker />}
            />
            <Route path="/auto-ticker/" element={<RQRandomStockTicker />} />
            <Route
              path="/daily-news-articles/"
              element={<RQDailyNewsArticles />}
            />
            <Route path="/news-symbol/" element={<RQNewsArticle />} />
            <Route
              path="/news-article-rating/:symbol/:exchange"
              element={<RQNewsArticleRating />}
            />
            <Route
              path="/economic-calendar/"
              element={<RQEconomicCalendar />}
            />
            <Route
              path="/charts/:title/:symbols/:timeFrame"
              element={<RQChartMultible />}
            />
            <Route path="/analyst-ratings/">
              <Route path=":threshold" element={<RQAnalystRating />} />
              <Route path="" element={<RQAnalystRating />} />
            </Route>
            <Route
              path="/chart/:title/:symbol/:timeFrame"
              element={<RQChartSingle />}
            />
            <Route path="/getCSV-Data" element={<CSVData />} />
            <Route
              path="/updateEconomicCalendar"
              element={<UpdateCalendar />}
            />
          </Routes>
        </div>
      </Router>
      <ReactQueryDevtools initial={false} position="bottom-right" />
    </QueryClientProvider>
  );
}

export default App;
