import axios from "axios";
import { format, subDays } from "date-fns";

export default async function getDailyNewsArticles() {
  const query = {
    query: `
    query{
      dailyNews {
        date
        headline
        headline_snippet
        description
        image
      }
    }
        `,
  };

  var options = {
    method: "post",
    url:
      process.env.REACT_APP_NODE_ENV === "Development"
        ? process.env.REACT_APP_DEV_API_URL
        : process.env.REACT_APP_API_URL,
    data: query,
    headers: {
      Authorization: `API_KEY ${process.env.REACT_APP_API_KEY}`,
    },
  };

  let response = await axios.request(options);
  const data = response.data.data.dailyNews;

  const filteredArray = data.filter((item) => {
    const pastThreeDays = format(subDays(new Date(), 3), "dd/MM/yyyy");
    console.log(pastThreeDays);
    if (item.data === null) {
      return false;
    }
    if (item.data > pastThreeDays) {
      return true;
    } else {
      return false;
    }
  });
  // Shuffle array
  const shuffled = data.sort(() => 0.5 - Math.random());
  // Get sub-array of first n elements after shuffled
  let selected = shuffled.slice(0, 4);

  return selected;
}
