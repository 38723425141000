import axios from "axios";
import { format, getMonth, subMonths } from "date-fns";

export default async function getCharts({ queryKey }) {
  const [_, symbols, timeFrame] = queryKey;

  const decimals = "2";

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const query = {
    query: `
    query{
      chartData(symbol: "${symbols}", timeFrame: "${timeFrame}"){
        data
      }
    }
        `,
  };

  var options = {
    method: "post",
    url:
      process.env.REACT_APP_NODE_ENV === "Development"
        ? process.env.REACT_APP_DEV_API_URL
        : process.env.REACT_APP_API_URL,
    data: query,
    headers: {
      Authorization: `API_KEY ${process.env.REACT_APP_API_KEY}`,
    },
  };

  const colors = [
    "rgba(229, 187, 12,1)",
    "rgba(255, 0, 255,1)",
    "rgba(96, 56, 19,1)",
  ];

  const chartData = {
    name: [],
    symbol: [],
    chartjs: {
      labels: [],
      datasets: [],
    },
  };

  const response = await axios.request(options);
  const data = response.data.data.chartData.data;
  const parsedData = JSON.parse(data);

  const dataArray = parsedData;

  console.log(dataArray);

  Object.keys(dataArray).map(function (key, index) {
    chartData.name.push(dataArray[key].meta.symbol);
    chartData.symbol.push(dataArray[key].meta.symbol);
    const dataset = {
      label: "",
      fill: false,
      lineTension: 0.1,
      backgroundColor: colors[index],
      borderColor: colors[index],
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: colors[index],
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: colors[index],
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      yAxisID: `y`,
      data: [],
    };
    chartData.chartjs.datasets.push(dataset);
  });

  dataArray[chartData.name[0]].values.map((item) => {
    const month = monthNames[getMonth(new Date(item.datetime))];
    chartData.chartjs.labels.push(month);
  });

  Object.keys(dataArray).map(function (key, index) {
    for (let i = 0; i < dataArray[key].values.length; i++) {
      chartData.chartjs.datasets[index].data.push(
        dataArray[key].values[i].close
      );
    }
  });

  return chartData;
}
