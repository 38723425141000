import React, { useState } from "react";
import Lottie from "lottie-react-web";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import LineChartSingle from "../Line Chart/LineChartSingle";
import SingleChartLottie from "./SingleChartLottie";

import getChart from "../../Queries/getChart";
import getGeneralNews from "../../Queries/getGeneralNews";

import loadingLottie from "../../Lottie/loadingLottie.json";
import lottieError from "../../Lottie/errorLottie.json";

function RQChartSingle() {
  const { symbol } = useParams();
  const { title } = useParams();
  const { timeFrame } = useParams();

  const { isLoading, data, isError } = useQuery(
    ["chart", symbol, timeFrame],
    getChart,
    { cacheTime: 9000000000 }
  );
  const {
    isLoading: isLoadingNews,
    data: newsData,
    isError: isErrorNews,
  } = useQuery(["generalNews"], getGeneralNews, { cacheTime: 9000000000 });

  const options = {
    scales: {
      yAxes: {
        display: false,
        ticks: {
          beginAtZero: true,
          padding: 2,
          font: {
            family: "Poppins", // Add your font here to change the font of your y axis
            size: 17,
          },
        },
      },
      xAxes: {
        gridLines: {
          offsetGridLines: true,
        },
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 13,
          padding: 20,
          font: {
            family: "Poppins", // Add your font here to change the font of your x axis
            size: 17,
            weight: "600",
          },
        },
      },
    },
  };

  if (isLoading && isLoadingNews) {
    return (
      <Lottie
        width={100}
        height={100}
        options={{
          animationData: loadingLottie,
          loop: true,
        }}
      />
    );
  }
  if (isError || isErrorNews) {
    return (
      <Lottie
        width={100}
        height={100}
        options={{
          animationData: lottieError,
          loop: true,
        }}
      />
    );
  }

  return (
    <>
      <SingleChartLottie
        symbol={symbol}
        title={title}
        data={data}
        timeFrame={timeFrame}
        newsData={newsData}
      />
      <LineChartSingle
        options={options}
        data={data}
        parentClassName="chartSingleContainer"
        childClassName="chartSingleChild"
      />
    </>
  );
}

export default RQChartSingle;
