export const symbolLookup = {
  AAPL: {
    name: "Apple Inc.",
    logo: "aapl.png",
  },
  AMZN: {
    name: "Amazon.com",
    logo: "amzn.png",
  },
  GOOGL: {
    name: "Alphabet Inc.",
    logo: "googl.png",
  },
  AEP: {
    name: "American Electric Power",
    logo: "aep.png",
  },
  AMN: {
    name: "AMN Healthcare Services",
    logo: "amn.png",
  },
  GME: {
    name: "GameStop.",
    logo: "gme.png",
  },
  NFLX: {
    name: "Netflix",
    logo: "nflx.png",
  },
  AMD: {
    name: "Advanced Micro Devices",
    logo: "amd.png",
  },
  BABA: {
    name: "Alibaba Group",
    logo: "baba.png",
  },
  TSLA: {
    name: "Tesla",
    logo: "tsla.png",
  },
  FB: {
    name: "Facebook",
    logo: "fb.png",
  },
  D: {
    name: "Dominion Energy",
    logo: "d.png",
  },
  FLT: {
    name: "Drone Delivery Canada",
    logo: "flt.png",
  },
  ISRG: {
    name: "Intuitive Surgical",
    logo: "isrg.png",
  },
  TRV: {
    name: "The Travelers Companies",
    logo: "trv.png",
  },
  BSX: {
    name: "Boston Scientific",
    logo: "bsx.png",
  },
  ULTA: {
    name: "Ulta Beauty",
    logo: "ulta.png",
  },
  M: {
    name: "Macy's Inc",
    logo: "m.png",
  },
  WU: {
    name: "Western Union",
    logo: "wu.png",
  },
  ARNC: {
    name: "Arconic",
    logo: "arnc.png",
  },
  TCOM: {
    name: "Trip.com Group",
    logo: "tcom.png",
  },
  EEM: {
    name: "iShares",
    logo: "eem.png",
  },
  SPY: {
    name: "The SPDR S&P",
    logo: "spy.png",
  },
  QQQ: {
    name: "Invesco",
    logo: "qqq.png",
  },
  FXI: {
    name: "iShares",
    logo: "fxi.png",
  },
  XLE: {
    name: "Energy Select Sector",
    logo: "xle.png",
  },
};
