import React from "react";
import Lottie from "lottie-react-web";
import { useQuery } from "react-query";
import getEconomicCalendar from "../../Queries/getEconomicCalendar";
import getGeneralNews from "../../Queries/getGeneralNews";
import loadingLottie from "../../Lottie/loadingLottie.json";
import lottieData from "./economicCalendarLottie";
import lottieError from "../../Lottie/errorLottie.json";

function RQEconomicCalendar() {
  const { isLoading, data, isError } = useQuery(
    ["econmicCalendar"],
    getEconomicCalendar
  );

  const {
    isLoading: isLoadingNews,
    data: newsData,
    isError: isErrorNews,
  } = useQuery(["generalNews"], getGeneralNews);

  if (isLoading && isLoadingNews) {
    return (
      <Lottie
        width={100}
        height={100}
        options={{
          animationData: loadingLottie,
          loop: true,
        }}
      />
    );
  }
  if (isError || isErrorNews) {
    return (
      <Lottie
        width={100}
        height={100}
        options={{
          animationData: lottieError,
          loop: true,
        }}
      />
    );
  }
  return lottieData(data, newsData);
}

export default RQEconomicCalendar;
